import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./ring-detailed-view.scss";

// icons
import { ReactComponent as LeftArrowIcon } from "../../assets/left_arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/right_arrow.svg";

// components

// interfaces
import { IRing } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";
import RingInfo from "../ring-detailed-view-info/ring-detailed-view-info";
import SaveToCollectionModal from "../save-to-collection/save-to-collection";

export interface RingDetailedViewProps {
  ring?: IRing;
  close: () => void;
  isModal?: boolean;
}

const RingDetailedView = ({ ring, close, isModal }: RingDetailedViewProps) => {
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();

  if (!ring) {
    return <div>{t("ringDetailedView.No ring data available")}</div>; // return early if ring is undefined
  }

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      close();
    }
  };

  const doesAnyHistoryEntryExistOrIsModal = location.key !== "default" || isModal;

  return (
    <div className="ring-detailed-view" onClick={handleBackgroundClick}>
      <div
        className={isModal ? "ring-detailed-view__top-bar is-modal" : "ring-detailed-view__top-bar"}
      >
        {doesAnyHistoryEntryExistOrIsModal && (
          <button
            className="ring-detailed-view__back-button"
            onClick={() => (isModal ? close() : navigate(-1))}
          >
            <LeftArrowIcon className="ring-detailed-view__icon black" />
            {t("general.Back")}
          </button>
        )}
      </div>
      <div
        className={
          isModal ? "ring-detailed-view__container is-modal" : "ring-detailed-view__container"
        }
        onClick={handleBackgroundClick}
      >
        <RingInfo
          ring={ring}
          toggleModal={() => setIsSaveModalOpen((prev) => !prev)}
          isSaved={isSaved}
        />
        <div className="ring-detailed-view__image-container">
          {Array.isArray(ring.url) && imageNumber > 0 ? (
            <button
              className="ring-detailed-view__previous-image"
              onClick={() => setImageNumber(imageNumber - 1)}
            >
              <LeftArrowIcon className="ring-detailed-view__icon" />
            </button>
          ) : null}
          {Array.isArray(ring.url) ? (
            <img src={ring.url[imageNumber]} alt={ring.imageName} />
          ) : (
            <img src={ring.url} alt={ring.imageName} />
          )}
          {Array.isArray(ring.url) && ring.url.length - 1 > imageNumber ? (
            <button
              className="ring-detailed-view__next-image"
              onClick={() => setImageNumber(imageNumber + 1)}
            >
              <RightArrowIcon className="ring-detailed-view__icon" />
            </button>
          ) : null}
        </div>
      </div>
      {isSaveModalOpen && (
        <SaveToCollectionModal
          close={() => setIsSaveModalOpen(false)}
          currentRingId={ring.id}
          setIsSaved={() => setIsSaved(true)}
        />
      )}
    </div>
  );
};

export default RingDetailedView;
