import React, { useState } from 'react';
import { auth } from "../../utils/firebase.utils";
import { sendPasswordResetEmail } from "firebase/auth";
import BasePage from "../../components/base-page/base-page";
import { useTranslation } from 'react-i18next';
import LoginButton from '../../components/login-button/login-button';
import FormInput from '../../components/form-input/form-input';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<string>('');
  const [resetSent, setResetSent] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      setError(null);
    } catch (error: any) {
      setResetSent(false);
      switch (error.message) {
        case "Firebase: Error (auth/invalid-email).":
          setError(t('forgot-password.Invalid email'))
          break;
        case "Firebase: Error (auth/user-not-found).":
          setError(null)
          setResetSent(true);
          break;
        case "Firebase: Error (auth/missing-email).":
          setError(t('forgot-password.Missing email'))
          break;
        default:
          setError(error.message);
          break;
      }
    }
  };

  return (
    <BasePage>
      <div>
        <h2>{t('forgot-password.Forgot password')}</h2>
        {resetSent ? (
          <p>{t('forgot-password.Password reset email sent. Check your email for further instructions.')}</p>
        ) : (
          <div>
            <p>{t('forgot-password.Enter your email address to reset your password.')}</p>
            <FormInput
                type="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)} 
                label={t('placeholder.Email')}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <LoginButton onClick={handleResetPassword}>{t('forgot-password.Reset password')}</LoginButton>
            
          </div>
        )}
      </div>
    </BasePage>
  );
};

export default ForgotPassword;
