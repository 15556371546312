import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

import FormInput from "../form-input/form-input";
import LoginButton from "../login-button/login-button";

import { createUserProfileDocument } from "../../utils/firebase.auth.utils";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase.utils";

import "./sign-up.scss";

const SignUp: React.FC = () => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);

  useEffect(() => {
    const fetchIpAndCountry = async () => {
      try {
        const response = await fetch("https://getcountryfromip-eult3duyqq-uc.a.run.app");
        const data = await response.json();
        setIpAddress(data.ip);
        setCountry(data.country);
      } catch (error) {
        console.error("Error fetching IP and country:", error);
      }
    };

    fetchIpAndCountry();
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    if (password !== confirmPassword || password.length < 6) {
      alert("passwords don't match or password is less than 6 characters");
      setIsSubmitting(false);
      return;
    }

    if (displayName.trim() === "") {
      alert("Display name must contain a character");
      setIsSubmitting(false);
      return;
    }

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      await createUserProfileDocument(user, {
        displayName,
        ipAddress,
        country,
      });

      setDisplayName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (error: any) {
      setIsSubmitting(false);
      const { code, message } = error;
      if (code === "auth/email-already-in-use") alert(message);
      console.error(error);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    switch (name) {
      case "displayName":
        setDisplayName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="sign-up">
      <h2 className="title">I do not have an account</h2>
      <span>Sign up with your email and password</span>
      <form className="sign-up-form" onSubmit={handleSubmit}>
        <FormInput
          type="text"
          name="displayName"
          value={displayName}
          onChange={handleChange}
          label="Display name"
          autoComplete="name"
          required
        />
        <FormInput
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          label="Email"
          autoComplete="email"
          required
        />
        <FormInput
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
          label="Password"
          required
        />
        <FormInput
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          label="Confirm password"
          required
        />
        <LoginButton type="submit">Sign Up</LoginButton>
      </form>
    </div>
  );
};

export default SignUp;
