export const NOT_LOGGED_IN = "not logged in";

export const NOT_LOGGED_IN_LIKE = "not_logged_in";

export const RING_SUBCOLLECTIONS = {
  DISLIKES: "dislikes",
  LIKES: "likes",
};

export const RINGS_URL = "https://rinderr.com/ring/";

export const typesData = [
  { value: "ring", label: "Ring", defaultValue: true },
  { value: "stone", label: "Stone", defaultValue: true },
];

export const stoneTypeTagsData = [
  { value: "alexandrite", label: "Alexandrite", defaultValue: true },
  { value: "amethyst", label: "Amethyst", defaultValue: true },
  { value: "beryl", label: "Beryl", defaultValue: true },
  { value: "bulletCut", label: "Bullet cut", defaultValue: true },
  { value: "chrysoprase", label: "Chrysoprase", defaultValue: true },
  {
    value: "crushedEmeraldInlay",
    label: "Crushed Emerald Inlay",
    defaultValue: true,
  },
  { value: "crushedTurquoise", label: "Crushed Turquoise", defaultValue: true },
  { value: "diamond", label: "Diamond", defaultValue: true },
  { value: "diamonds", label: "Diamonds", defaultValue: true },
  { value: "emeralds", label: "Emeralds", defaultValue: true },
  { value: "fireAgate", label: "Fire Agate", defaultValue: true },
  { value: "garnet", label: "Garnet", defaultValue: true },
  { value: "labAlexandrite", label: "Lab Alexandrite", defaultValue: true },
  { value: "labDiamond", label: "Lab Diamond", defaultValue: true },
  { value: "labradorite", label: "Labradorite", defaultValue: true },
  { value: "lapisLazuli", label: "Lapis Lazuli", defaultValue: true },
  { value: "moissanite", label: "Moissanite", defaultValue: true },
  { value: "moonstone", label: "Moonstone", defaultValue: true },
  { value: "moldavite", label: "Moldavite", defaultValue: true },
  { value: "morganite", label: "Morganite", defaultValue: true },
  { value: "mossAgate", label: "Moss Agate", defaultValue: true },
  { value: "naturalEmerald", label: "Natural Emerald", defaultValue: true },
  { value: "noStone", label: "No stone", defaultValue: true },
  { value: "onyx", label: "Onyx", defaultValue: true },
  { value: "opal", label: "Opal", defaultValue: true },
  { value: "peridot", label: "Peridot", defaultValue: true },
  { value: "quartz", label: "Quartz", defaultValue: true },
  { value: "rubies", label: "Rubies", defaultValue: true },
  { value: "ruby", label: "Ruby", defaultValue: true },
  { value: "sapphire", label: "Sapphire", defaultValue: true },
  { value: "spinel", label: "Spinel", defaultValue: true },
  { value: "sunstone", label: "Sunstone", defaultValue: true },
  { value: "tanzanite", label: "Tanzanite", defaultValue: true },
  { value: "topaz", label: "Topaz", defaultValue: true },
  { value: "tourmaline", label: "Tourmaline", defaultValue: true },
  { value: "tsavorite", label: "Tsavorite", defaultValue: true },
  { value: "turquoise", label: "Turquoise", defaultValue: true },
  { value: "zircon", label: "Zircon", defaultValue: true },
];

export const stoneCutTagsData = [
  { value: "asscher", label: "Asscher cut", defaultValue: true },
  { value: "baguette", label: "Baguette cut", defaultValue: true },
  { value: "brilliant", label: "Brilliant cut", defaultValue: true },
  { value: "butterflyCut", label: "Butterfly cut", defaultValue: true },
  { value: "cabochon", label: "Cabochon cut", defaultValue: true },
  { value: "crissCutCushion", label: "Criss cut Cushion", defaultValue: true },
  { value: "cushion", label: "Cushion cut", defaultValue: true },
  { value: "dutchMarquise", label: "Dutch Marquise cut", defaultValue: true },
  { value: "emerald", label: "Emerald cut", defaultValue: true },
  { value: "emeraldCrissCut", label: "Emerald Criss cut", defaultValue: true },
  { value: "halfMoonCut", label: "Half moon cut", defaultValue: true },
  { value: "heart", label: "Heart cut", defaultValue: true },
  { value: "hexagon", label: "Hexagon cut", defaultValue: true },
  { value: "jubileeCut", label: "Jubilee cut", defaultValue: true },
  { value: "kite", label: "Kite cut", defaultValue: true },
  { value: "lipsCut", label: "Lips cut", defaultValue: true },
  { value: "lozengeCut", label: "Lozenge Cut", defaultValue: true },
  { value: "marquise", label: "Marquise cut", defaultValue: true },
  { value: "moval", label: "Moval cut", defaultValue: true },
  { value: "octagon", label: "Octagon cut", defaultValue: true },
  { value: "oer", label: "Old European round", defaultValue: true },
  { value: "omc", label: "Old mine cut", defaultValue: true },
  {
    value: "oldMineCushionCut",
    label: "Old mine Cushion cut",
    defaultValue: true,
  },
  {
    value: "oldMineEmeraldCut",
    label: "Old mine Emerald cut",
    defaultValue: true,
  },
  {
    value: "oldMineMarquiseCut",
    label: "Old mine Marquise cut",
    defaultValue: true,
  },
  { value: "oldMineOvalCut", label: "Old mine Oval cut", defaultValue: true },
  { value: "oldMinePearCut", label: "Old mine Pear cut", defaultValue: true },
  {
    value: "oldMinePrincessCut",
    label: "Old mine Princess cut",
    defaultValue: true,
  },
  {
    value: "oldMineRadiantCut",
    label: "Old mine Radiant cut",
    defaultValue: true,
  },
  { value: "oval", label: "Oval cut", defaultValue: true },
  { value: "pentagonCut", label: "Pentagon cut", defaultValue: true },
  { value: "pear", label: "Pear cut", defaultValue: true },
  { value: "portraitCut", label: "Portrait cut", defaultValue: true },
  { value: "princess", label: "Princess cut", defaultValue: true },
  { value: "radiant", label: "Radiant cut", defaultValue: true },
  {
    value: "rectangleCushion",
    label: "Rectangle Cushion cut",
    defaultValue: true,
  },
  { value: "rose", label: "Rose cut", defaultValue: true },
  { value: "roseCutOval", label: "Rose cut Oval", defaultValue: true },
  { value: "round", label: "Round cut", defaultValue: true },
  { value: "septagon", label: "Septagon cut", defaultValue: true },
  { value: "shieldCut", label: "Shield cut", defaultValue: true },
  { value: "spearCut", label: "Spear cut", defaultValue: true },
  { value: "square", label: "Square cut", defaultValue: true },
  { value: "squareCushion", label: "Square cushion cut", defaultValue: true },
  { value: "stepCut", label: "Step cut", defaultValue: true },
  { value: "stepCutOctagon", label: "Step cut Octagon", defaultValue: true },
  {
    value: "taperedBaguette",
    label: "Tapered Baguette cut",
    defaultValue: true,
  },
  { value: "trapezoid", label: "Trapezoid cut", defaultValue: true },
  { value: "triangle", label: "Triangle cut", defaultValue: true },
  { value: "trillion", label: "Trillion cut", defaultValue: true },
];

export const colorTagsData = [
  { value: "aquamarine", label: "Aquamarine", defaultValue: true },
  { value: "black", label: "Black", defaultValue: true },
  { value: "blue", label: "Blue", defaultValue: true },
  { value: "blueGreen", label: "Blue/Green", defaultValue: true },
  { value: "bluePurple", label: "Blue Purple", defaultValue: true },
  { value: "blueRainbow", label: "Blue Rainbow", defaultValue: true },
  { value: "blue/teal", label: "Blue/Teal", defaultValue: true },
  { value: "brown", label: "Brown", defaultValue: true },
  { value: "brownish", label: "Brownish", defaultValue: true },
  { value: "burgundy", label: "Burgundy", defaultValue: true },
  { value: "champagne", label: "Champagne", defaultValue: true },
  { value: "darkBlue", label: "Dark Blue", defaultValue: true },
  { value: "darkGreen", label: "Dark Green", defaultValue: true },
  { value: "green", label: "Green", defaultValue: true },
  { value: "greenBlue", label: "Green Blue", defaultValue: true },
  { value: "greenWhite", label: "Green White", defaultValue: true },
  { value: "green/mint", label: "Green/Mint", defaultValue: true },
  { value: "grey", label: "Grey", defaultValue: true },
  { value: "intenseGreen", label: "Intense Green", defaultValue: true },
  { value: "lavender", label: "Lavender", defaultValue: true },
  { value: "lightBlue", label: "Light Blue", defaultValue: true },
  { value: "lightGreen", label: "Light Green", defaultValue: true },
  { value: "londonBlue", label: "London Blue", defaultValue: true },
  { value: "mandarine", label: "Mandarine", defaultValue: true },
  { value: "mint", label: "Mint", defaultValue: true },
  { value: "mintGreen", label: "Mint Green", defaultValue: true },
  { value: "mint/green", label: "Mint/Green", defaultValue: true },
  { value: "orange", label: "Orange", defaultValue: true },
  { value: "offWhite", label: "Off-white", defaultValue: true },
  { value: "pastelBlue", label: "Pastel Blue", defaultValue: true },
  { value: "peach", label: "Peach", defaultValue: true },
  { value: "peachPink", label: "Peach Pink", defaultValue: true },
  {
    value: "peachPinkChampagne",
    label: "Peach Pink Champagne",
    defaultValue: true,
  },
  { value: "peachyPink", label: "Peachy Pink", defaultValue: true },
  { value: "pink", label: "Pink", defaultValue: true },
  { value: "pinkPeach", label: "Pink Peach", defaultValue: true },
  { value: "pinkPurple", label: "Pink Purple", defaultValue: true },
  { value: "pinkRed", label: "Pink Red", defaultValue: true },
  { value: "pinkish-green", label: "Pinkish Green", defaultValue: true },
  { value: "pink/purple", label: "Pink/Purple", defaultValue: true },
  { value: "purple", label: "Purple", defaultValue: true },
  { value: "rainbow", label: "Rainbow", defaultValue: true },
  { value: "red", label: "Red", defaultValue: true },
  { value: "salt&pepper", label: "Salt & Pepper", defaultValue: true },
  { value: "silveryBlue", label: "Silvery Blue", defaultValue: true },
  {
    value: "silveryBluishGreen",
    label: "Silvery Bluish Green",
    defaultValue: true,
  },
  { value: "skyBlue", label: "Sky Blue", defaultValue: true },
  { value: "softPink", label: "Soft Pink", defaultValue: true },
  { value: "teal", label: "Teal", defaultValue: true },
  { value: "tealBlue", label: "Teal Blue", defaultValue: true },
  { value: "violet", label: "Violet", defaultValue: true },
  { value: "watermelon", label: "Watermelon", defaultValue: true },
  { value: "white", label: "White", defaultValue: true },
  { value: "whiteGreen", label: "White Green", defaultValue: true },
  { value: "whiteGrey", label: "White Grey", defaultValue: true },
  { value: "yellow", label: "Yellow", defaultValue: true },
];

export const settingTagsData = [
  { value: "basket", label: "Basket setting", defaultValue: true },
  { value: "bridgeAccent", label: "Bridge Accent", defaultValue: true },
  {
    value: "halfBezelSetting",
    label: "Half bezel setting",
    defaultValue: true,
  },
  { value: "bezelSetting", label: "Bezel setting", defaultValue: true },
  { value: "cathedralSetting", label: "Cathedral setting", defaultValue: true },
  { value: "channelSetting", label: "Channel setting", defaultValue: true },
  { value: "clusterSetting", label: "Cluster setting", defaultValue: true },
  { value: "clusterHalo", label: "Cluster halo", defaultValue: true },
  { value: "clawSetting", label: "Claw prong", defaultValue: true },
  {
    value: "distancePaveSetting",
    label: "Distance pave setting",
    defaultValue: true,
  },
  { value: "doubleHalo", label: "Double halo", defaultValue: true },
  { value: "doubleProng", label: "Double prong", defaultValue: true },
  { value: "eightProng", label: "Eight prong setting", defaultValue: true },
  { value: "fourProng", label: "Four prong setting", defaultValue: true },
  { value: "fiveProng", label: "Five prong setting", defaultValue: true },
  { value: "haloSetting", label: "Halo setting", defaultValue: true },
  { value: "nineProng", label: "Nine prong setting", defaultValue: true },
  { value: "nsweSetting", label: "NSWE setting", defaultValue: true },
  { value: "paveSetting", label: "Pave setting", defaultValue: true },
  { value: "pegHead", label: "Peg head", defaultValue: true },
  { value: "roundProng", label: "Round prong", defaultValue: true },
  { value: "scallopSetting", label: "Scallop setting", defaultValue: true },
  { value: "sixProng", label: "Six prong setting", defaultValue: true },
  { value: "tabProng", label: "Tab prong", defaultValue: true },
  { value: "threeProng", label: "Three prong setting", defaultValue: true },
  { value: "tensionSetting", label: "Tension setting", defaultValue: true },
  { value: "tenProng", label: "Ten prong setting", defaultValue: true },
  { value: "trellisSetting", label: "Trellis setting", defaultValue: true },
  { value: "tripleProng", label: "Triple prong", defaultValue: true },
  { value: "twelveProng", label: "Twelve prong setting", defaultValue: true },
  { value: "uProng", label: "U-Prong", defaultValue: true },
  { value: "vProng", label: "V-Prong", defaultValue: true },
];

export const styleTagsData = [
  { value: "artDeco", label: "Art Deco", defaultValue: true },
  { value: "accentStones", label: "Accent stones", defaultValue: true },
  { value: "butterflyShape", label: "Butterfly shape", defaultValue: true },
  { value: "brushedFinish", label: "Brushed finish", defaultValue: true },
  { value: "bypassShank", label: "Bypass shank", defaultValue: true },
  { value: "cherryShape", label: "Cherry shape", defaultValue: true },
  { value: "circleShape", label: "circleShape", defaultValue: true },
  { value: "cocktail", label: "Cocktail", defaultValue: true },
  { value: "cutout", label: "Cut Out shank", defaultValue: true },
  { value: "eternity", label: "Eternity", defaultValue: true },
  { value: "eastToWest", label: "East to west", defaultValue: true },
  { value: "eyeShape", label: "Eye shape", defaultValue: true },
  { value: "fiveStone", label: "Five stone", defaultValue: true },
  { value: "flawShank", label: "Flat shank", defaultValue: true },
  { value: "flowerShape", label: "Flower shape", defaultValue: true },
  { value: "halfEternity", label: "Half eternity", defaultValue: true },
  { value: "halfRoundShank", label: "Half Round shank", defaultValue: true },
  { value: "hexagonOutline", label: "Hexagon outline", defaultValue: true },
  { value: "hiddenHalo", label: "Hidden Halo", defaultValue: true },
  { value: "horseshoeShape", label: "Horseshoe shape", defaultValue: true },
  { value: "knifeEdge", label: "Knife edge shank", defaultValue: true },
  { value: "milgrain", label: "Milgrain", defaultValue: true },
  { value: "octagonOutline", label: "Octagon outline", defaultValue: true },
  { value: "organic", label: "Organic", defaultValue: true },
  { value: "openRing", label: "Open ring", defaultValue: true },
  { value: "octagonOutline", label: "Octagon outline", defaultValue: true },
  { value: "ropeShank", label: "Rope shank", defaultValue: true },
  { value: "sixStone", label: "Six stone", defaultValue: true },
  { value: "solitaire", label: "Solitaire", defaultValue: true },
  { value: "splitShank", label: "Split shank", defaultValue: true },
  { value: "spikedShank", label: "Spiked shank", defaultValue: true },
  { value: "taperedShank", label: "Tapered shank", defaultValue: true },
  { value: "threeStone", label: "Three stone", defaultValue: true },
  { value: "toiEtMoi", label: "Toi et Moi", defaultValue: true },
  { value: "twistedShank", label: "Twisted shank", defaultValue: true },
  { value: "twoStone", label: "Two stone", defaultValue: true },
  { value: "vShaped", label: "V-shaped", defaultValue: true },
  { value: "vintage", label: "Vintage", defaultValue: true },
  { value: "whimsical", label: "Whimsical", defaultValue: true },
];

export const metalTagsData = [
  { value: "10k", label: "10k", defaultValue: true },
  { value: "14k", label: "14k", defaultValue: true },
  { value: "18k", label: "18k", defaultValue: true },
  { value: "gold", label: "Gold", defaultValue: true },
  { value: "platinum", label: "Platinum", defaultValue: true },
  { value: "roseGold", label: "Rose Gold", defaultValue: true },
  { value: "silver", label: "Silver", defaultValue: true },
  { value: "whiteGold", label: "White Gold", defaultValue: true },
  { value: "yellowGold", label: "Yellow Gold", defaultValue: true },
];

export const caratData = [
  { value: "5", label: "+0.1", defaultValue: true },
  { value: "10", label: "+1", defaultValue: true },
  { value: "15", label: "+2.5", defaultValue: true },
  { value: "20", label: "+4.5", defaultValue: true },
];

export const currencyData = [
  { value: "USD", label: "$", defaultValue: true },
  { value: "EUR", label: "€", defaultValue: true },
];

export const getValuesAsObject = (optionsData) => {
  const valuesObject = {};

  optionsData.forEach((option) => {
    valuesObject[option.value] = option.defaultValue;
  });

  return valuesObject;
};

export const getLabelInDataByValue = (data, value) => {
  // eslint-disable-next-line
  const foundItem = data.find((item) => item.value == value);
  return foundItem ? foundItem.label : null;
};

export const getObjectsFromDataMatchingArrayOrString = (
  data,
  arrayOrString,
) => {
  return data.filter((object) => {
    if (Array.isArray(arrayOrString))
      return arrayOrString.includes(object.value);
    else return arrayOrString === object.value;
  });
};

export const devRingData = [
  [
    {
      name: "Salt and Pepper Diamond Ring, Marquise Diamond Ring, Diamond Engagement Ring, Marquise Galaxy Diamond Ring",
      salesPrice: 1690,
      currency: "$",
      salesUrl:
        "https://www.etsy.com/listing/973954660/salt-and-pepper-diamond-ring-marquise?click_key=cc9f5d7cab47ad7fbb7e9351a905e464fc3e34bd%3A973954660&click_sum=cd238c9f&ref=shop_home_feat_1&frs=1",
      description:
        "Salt and pepper diamond engagement ring with a marquise-cut galaxy diamond in 14k, 18k solid gold or platinum. Shinny rustic ring with a half diamond halo by Threelayers.The design can be made with gemstones of your choosing.If you would prefer a custom item, please contact us before purchase.DetailsCenter gemstone: marquise-cut natural salt and pepper diamondWeight: approx. 0.4-0.5ctAccent stones: round-cut white diamondsMeasurements: approx. 1.1mm (9)Diamond quality: color grade F-G, clarity grade VS, conflict-freeBand width: approx. 1.8mmMaterial: 14k/18k yellow, white, rose solid gold or platinumSizes available: 3-8 (Larger and smaller sizes are available as well. Priced upon request.)Procedure informationPlease select the material and size from the drop-down menu on the right side of the listing.If you have any special requests or questions, please do not hesitate to contact us.Shipping informationThe item will be gift wrapped and shipped with insured express shipping from Europe.https://www.etsy.com/shop/MinimalVShttps://www.etsy.com/shop/capucinnehttps://www.etsy.com/shop/threelayers",
      waitingForApproval: true,
      type: "ring",
      url: "https://i.etsystatic.com/9725687/r/il/b65f17/2967133918/il_794xN.2967133918_gfp7.jpg",
      actualStoneSize: 0.5,
      mainStoneSize: 5,
      stoneCut: ["marquise", "diamond"],
      uploadedBy: {
        id: "KCoPkmzGxIh0jIMf12aVtyRP9Xc2",
        name: "Threelayers",
      },
    },
    {
      name: "Kite moss agate engagement ring, Mossy kite and diamond engagement ring, Geometric engagement ring for modern wedding",
      salesPrice: 1150,
      currency: "$",
      salesUrl:
        "https://www.etsy.com/listing/1074015980/kite-moss-agate-engagement-ring-mossy?click_key=544284d4b91da6e03a13255edeb2737732a861e8%3A1074015980&click_sum=91a4cb28&ref=shop_home_feat_2&frs=1",
      description:
        "Kite moss agate engagement ring with a half-eternity pave diamond band. Unique geometric mossy agate ring in 14, 18k solid gold, or platinum by Threelayers.Please, select your moss agate from the last picture and let us know the number of the chosen stone in the notes at the checkout or send us a message.We are the original jewelry designers to use moss agate and create geometric moss agate one-of-a-kind jewelry. Please beware of other jewelry designers using moss agate as their designs are replicas of our bestsellers and cannot match our high standards of production and authentication of quality.All moss agates are natural and hand-cut, which can result in minor irregularities on the surface and/or can be visible in the cut and shape of the stones pictured.The design can be made with gemstones of your choosing. If you would prefer a custom ring, please contact us before purchase.This item is customized.DetailsMain gemstone: kite-cut moss agateMeasurements: approx. 12x7mmAccent stones: round-cut white diamondsMeasurements: approx. 1.25mm (12)Quality: clarity grade VS, color grade F-G, conflict-freeBand width: approx. 1.8mmMaterial: 14k/18k yellow, white, rose solid gold or platinumSizes available: 3-8 (Larger and smaller sizes are available as well. Priced upon request.)Procedure informationPlease select the material and size from the drop-down menu on the right side of the listing.If you have any special requests or questions, please do not hesitate to contact us.Shipping informationThe item will be gift-wrapped and shipped with insured express shipping from Europe.https://www.capucinne.comhttps://www.etsy.com/shop/MinimalVShttps://www.etsy.com/shop/capucinnehttps://www.etsy.com/shop/threelayers",
      waitingForApproval: true,
      type: "ring",
      url: "https://i.etsystatic.com/9725687/r/il/bf4362/3389286833/il_794xN.3389286833_erh6.jpg",
      mainStoneSize: "2.5-4.5",
      stoneCut: ["kite", "diamond"],
      UploadedBy: {
        id: "KCoPkmzGxIh0jIMf12aVtyRP9Xc3",
        name: "Threelayers",
      },
    },
    {
      name: "Oyster turquoise ring, Gemstone engagement ring, Freckled stone solitaire, Large bohemian ring",
      salesPrice: 300,
      currency: "$",
      salesUrl:
        "https://www.etsy.com/listing/871747728/oyster-turquoise-ring-gemstone?click_key=40a7b4e1f43fb946385f2ea281334114540cc303%3A871747728&click_sum=16ea7537&ref=shop_home_feat_3&frs=1",
      description:
        "Oyster turquoise ring with an oval rose-cut colorful gemstone in a dainty bezel setting. Unique bohemian engagement solitaire with a large freckled one of a kind natural oyster turquoise.The design can be made with gemstones of your choosing. If you would prefer a custom item, please contact us before purchase.DetailsMain gemstone: oval rose-cut oyster turquoise Size: approx. 12x10mmBand width: approx. 2mmMaterial: sterling silver 14k/18k yellow, white, rose solid gold, platinumSize: US 2-8Larger and smaller sizes are available as well. Priced upon request.Procedure informationPlease select the material and size from the drop-down menu on the right side of the listing.If you have any special requests or questions, please do not hesitate to contact us before purchase.Shipping informationThe item will be gift wrapped and shipped with insured express shipping from Europe.https://www.etsy.com/shop/MinimalVShttps://www.etsy.com/shop/capucinnehttps://www.etsy.com/shop/threelayers",
      waitingForApproval: true,
      type: "ring",
      url: "https://i.etsystatic.com/9725687/r/il/e255e3/3767114693/il_794xN.3767114693_saz4.jpg",
      mainStoneSize: "4.5+",
      stoneCut: ["rose", "turquoise"],
      UploadedBy: {
        id: "KCoPkmzGxIh0jIMf12aVtyRP9Xc4",
        name: "Threelayers",
      },
    },
    {
      name: "Opal engagement ring, Small australian opal ring, Tiny solitaire, Dainty round bezel ring",
      salesPrice: 280,
      currency: "$",
      salesUrl:
        "https://www.etsy.com/listing/662432913/opal-engagement-ring-small-australian?click_key=ef91c69b19e0e656e1ab7eef3e602743b7d1f548%3A662432913&click_sum=eb44714b&ref=shop_home_active_22&frs=1",
      description:
        "Opal engagement ring with a small round-cut Australian opal cabochon in a dainty bezel setting and thin band. Tiny solitaire ring with a tiny natural opal, perfect as a modern petite wedding engagement, simple stacking ring or October birthstone gift.Please, see the moonstone ring here: https://www.etsy.com/listing/690398004/The design can be made with gemstones of your choosing. If you would prefer a custom item, please contact us before purchase.DetailsMain gemstone: round-cut Australian opalSize: approx. 3mmBand width: approx. 1.3mmMaterial: sterling silver 14k/18k yellow, white, rose solid goldSize: US 2-8Larger and smaller sizes are available as well. Priced upon request.Procedure informationPlease select the material and size from the drop-down menu on the right side of the listing.If you have any special requests or questions, please do not hesitate to contact us before purchase.Shipping informationThe item will be gift wrapped and shipped with insured express shipping from Europe.https://www.etsy.com/shop/MinimalVShttps://www.etsy.com/shop/capucinnehttps://www.etsy.com/shop/threelayers",
      waitingForApproval: true,
      type: "ring",
      url: "https://i.etsystatic.com/9725687/r/il/9e3b10/3719328724/il_794xN.3719328724_hz7i.jpg",
      mainStoneSize: "0-1",
      stoneCut: ["round", "cabochon", "opal"],
      UploadedBy: {
        id: "KCoPkmzGxIh0jIMf12aVtyRP9Xc118",
        name: "Threelayers",
      },
    },
  ],
];
