import React from "react";
import "./sign-in.scss";
import { Link } from "react-router-dom";

// components
import LoginButton from "../login-button/login-button";

// firebase
import { signInWithGoogle } from "../../utils/firebase.auth.utils";
import { useTranslation } from "react-i18next";

interface ISignIn {
  handleEmailClick: () => void;
}

const SignIn = ({ handleEmailClick }: ISignIn) => {
  const { t } = useTranslation();
  return (
    <div className="sign-in">
      <h2>{t('signIn.Swipe rings')}</h2>
      <span>{t('signIn.And find your perfect match')}</span>
      <div className="buttons">
        <LoginButton onClick={signInWithGoogle} isGoogleSignIn>
        {t('signIn.Sign in with Google')}
        </LoginButton>
        <LoginButton onClick={() => handleEmailClick()}>{t('signIn.Sign in with email')}</LoginButton>
      </div>
      <span className="smaller">
      {t('signIn.By creating or signing into your account you agree to our')} <Link to="/terms">{t('signIn.terms')}</Link>
      </span>
    </div>
  );
};

export default SignIn;
