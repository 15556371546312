import React, { useState, useEffect } from "react";
import "./upload-image.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ConfettiExplosion from "react-confetti-explosion";

// utils
import {
  storage,
  addRingToFirebase,
  addUploadedRingWithIdToUser,
} from "../../utils/firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  NOT_LOGGED_IN,
  getObjectsFromDataMatchingArrayOrString,
  stoneCutTagsData,
  stoneTypeTagsData,
  colorTagsData,
  styleTagsData,
  metalTagsData,
} from "../../utils/constants.utils";

// selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";

// components
import UploadImageForm from "../../components/upload-image-form/upload-image-form";
import BasePage from "../../components/base-page/base-page";
import { camelCase } from "../../utils/string.utils";
import { IRing } from "../../utils/interfaces";
// import Spinner from "../../components/spinner/spinner";

interface UploadImageProps {
  currentUser: {
    id: string;
    displayName: string;
  } | null;
}

const UploadImage: React.FC<UploadImageProps> = ({ currentUser }) => {
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [imageAsFileArray, setImageAsFileArray] = useState<File[]>([]);
  const [cadUrl, setCadUrl] = useState<string[]>([]);
  const [cadAsFileArray, setCadAsFileArray] = useState<File[]>([]);
  const [imageUrlsOnFirebase, setImageUrlsOnFirebase] = useState<string[]>([]);
  const [cadUrlsOnFirebase, setCadUrlsOnFirebase] = useState<string[]>([]);
  const [stoneCut, setStoneCut] = useState<string[]>(["round"]);
  const [mainStoneSize, setMainStoneSize] = useState<number | null>(null);
  const [salesPrice, setSalesPrice] = useState<number>(0);
  const [salesUrl, setSalesUrl] = useState<string>("");
  const [imageName, setImageName] = useState<string>("");
  const [uploadAsUser, setUploadAsUser] = useState<{ id: string; name: string }>({ id: "", name: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ipAdress, setIpAddress] = useState<string>("");
  const [type, setType] = useState<string>("ring");
  const [successfulUpload, setSuccessfulUpload] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();

    return () => {
      // Cleanup function
      setIpAddress("");
    };
  }, []);

  useEffect(() => {
    if (!imageUrl.length) return;
    // Check if all urls in imageUrl have been converted to an url on Firebase
    if (imageUrlsOnFirebase.length === imageUrl.length) {
      let stoneSize;
      if (mainStoneSize && mainStoneSize < 1) stoneSize = 5;
      if (mainStoneSize && mainStoneSize >= 1 && mainStoneSize < 2.5) stoneSize = 10;
      if (mainStoneSize && mainStoneSize >= 2.5 && mainStoneSize < 4.5) stoneSize = 15;
      if (mainStoneSize && mainStoneSize > 4.5) stoneSize = 20;

      const camelCasedNewStoneCut = stoneCut.map((cut) => {
        if (
          getObjectsFromDataMatchingArrayOrString(
            [
              ...stoneCutTagsData,
              ...stoneTypeTagsData,
              ...colorTagsData,
              ...styleTagsData,
              ...metalTagsData,
            ],
            cut
          ).length === 0
        ) {
          return camelCase(cut);
        }
        return cut;
      });

      let ring: Partial<IRing>;
      let uploadedBy =
        currentUser !== null
          ? { id: currentUser.id, name: currentUser.displayName, ipAdress }
          : { id: NOT_LOGGED_IN, name: NOT_LOGGED_IN, ipAdress };
      if (uploadAsUser.id !== "") {
        uploadedBy = { ...uploadAsUser, ipAdress };
      }
      ring = {
        uploadedOn: Date.now(),
        url: imageUrlsOnFirebase,
        cadUrls: cadUrlsOnFirebase,
        numberOfLikes: 0,
        numberOfDislikes: 0,
        gender: "women",
        type,
        stoneCut: camelCasedNewStoneCut,
        mainStoneSize: stoneSize as 5 | 10 | 15 | 20,
        actualStoneSize: parseFloat(mainStoneSize?.toString().replace(/,/g, "") || "0"),
        imageName,
        rank: 0,
        currency: "USD",
        salesPrice,
        salesUrl,
        waitingForApproval: true,
        uploadedBy,
        isDeleted: false,
        shuffledSortOrder: Math.random(),
      };
      addRingToFirebase(ring as IRing)
        .then((ringId) => {
          if (!ringId || typeof ringId !== 'string') return;
          if (!currentUser) return;

          addUploadedRingWithIdToUser(ringId, uploadedBy.id);
          resetAllFields();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          alert("could not upload image");
          resetAllFields();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrlsOnFirebase]);

  const handleUpload = async () => {
    setIsLoading(true);
    const urls: string[] = [];
    const cadUrls: string[] = [];

    for (let i = 0; i < cadAsFileArray.length; i++) {
      const url = await handleFireBaseUpload(cadAsFileArray[i], setCadUrlsOnFirebase);
      cadUrls.push(url);
    }

    for (let i = 0; i < imageAsFileArray.length; i++) {
      const url = await handleFireBaseUpload(imageAsFileArray[i], setImageUrlsOnFirebase);
      urls.push(url);
    }

    setCadUrlsOnFirebase(cadUrls);
    setImageUrlsOnFirebase(urls);
    setIsLoading(false);
  };

  const resetAllFields = () => {
    setStoneCut([""]);
    setMainStoneSize(0);
    setImageUrl([]);
    setImageUrlsOnFirebase([]);
    setImageAsFileArray([]);
    setCadAsFileArray([]);
    setCadUrl([]);
    setCadUrlsOnFirebase([]);
    setIsLoading(false);
  };

  const handleFireBaseUpload = async (
    file: File,
    setUrlsOnFirebase: React.Dispatch<React.SetStateAction<string[]>>
  ): Promise<string> => {
    if (
      !(
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/tiff" ||
        file.type === "image/webp"
      )
    ) {
      alert(`Not an image. The image file is a ${typeof file}`);
      return "";
    }

    const currentTime = Date.now();
    const fileName = `${currentTime}-${file.name}`;
    setImageName(imageName);

    const storageRef = ref(storage, `/women/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              console.error("Unhandled state update");
          }
        },
        (error) => {
          console.error("Error uploading image", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUrlsOnFirebase((currentState) => [...currentState, downloadURL]);
            console.log("Image uploaded successfully");
            setSuccessfulUpload(!successfulUpload);
            setProgress(0);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const mainPageContent = () => {
    if (successfulUpload)
      return (
        <>
          <ConfettiExplosion
            force={0.6}
            duration={5000}
            particleCount={200}
            height={1600}
            width={1600}
          />
          <h1 className="success-message">Boom! 🎉</h1>
          <p>Your ring is currently undergoing review by the team at Rinder.</p>
          <p>
            Once approved, it will be ready for fellow ring-lovers to discover and appreciate on the
            platform.
          </p>
          <p>
            Please check back in a day or two, and your ring should be live for all to see and
            enjoy. ❤️
          </p>
          <button
            onClick={() => setSuccessfulUpload(!successfulUpload)}
            className="primary inverted"
          >
            Upload more rings
          </button>
        </>
      );
    else
      return (
        <>
          <h1>Upload your ring</h1>
          <span className="upload-image__subtitle">
            Share your ring with the community or help it find a new home
          </span>
          <UploadImageForm
            setStateImageUrl={setImageUrl}
            setStateImageFile={setImageAsFileArray}
            stateImageFile={imageAsFileArray}
            stoneCut={stoneCut}
            setStoneCut={setStoneCut}
            mainStoneSize={mainStoneSize}
            setMainStoneSize={setMainStoneSize}
            stateImageUrl={imageUrl}
            setSalesPrice={setSalesPrice}
            setSalesUrl={setSalesUrl}
            salesUrl={salesUrl}
            salesPrice={salesPrice}
            setType={setType}
            setUploadAsUser={setUploadAsUser}
            handleUpload={handleUpload}
            setStateCadUrl={setCadUrl}
            setStateCadFile={setCadAsFileArray}
            stateCadUrl={cadUrl}
            stateCadFile={cadAsFileArray}
          />
          <span className="upload-image__terms-text">
            By uploading your ring you agree to follow our <Link to="/terms">terms</Link>
          </span>
        </>
      );
  };

  return (
    <BasePage>
      <div className="upload-image">
        {isLoading ? (
          <progress value={progress} max="100">
            {progress}%
          </progress>
        ) : (
          <div className="paper upload-image__container">{mainPageContent()}</div>
        )}
      </div>
    </BasePage>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(UploadImage);
