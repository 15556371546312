import { SyntheticEvent } from "react";
import "./liked-rings-container.scss";
import Spinner from "../spinner/spinner";
import { removeLikeFromRing, unlikeRingForUser } from "../../utils/firebase.utils";
import ImageBox from "../image-box/image-box";
import { setLikedRings } from "../../redux/rings/rings.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";
import { ICurrentUser, IRing } from "../../utils/interfaces";
import { connect } from "react-redux";

interface IAppProps {
  setLikedRingsState: (likedRings: IRing[]) => void;
  setLikedRingsParent: (likedRings: IRing[]) => void;
  likedRingsParent: IRing[];
  currentUser: ICurrentUser;
  isGuest?: boolean;
  isLoading?: boolean;
}

const LikedRingsContainer = ({
  setLikedRingsState,
  likedRingsParent,
  setLikedRingsParent,
  currentUser,
  isGuest,
  isLoading = true,
}: IAppProps) => {
  const removeLike = (event: SyntheticEvent, ringId: string) => {
    event.stopPropagation();
    if (window.confirm("Are you sure you want to remove this saved ring?")) {
      unlikeRingForUser(ringId, currentUser.id);
      removeLikeFromRing(ringId, currentUser.id);
      const updatedRingList = likedRingsParent.filter(
        (ringFromLikedRings) => ringId !== ringFromLikedRings.id,
      );
      setLikedRingsParent(updatedRingList);
      setLikedRingsState(updatedRingList);
    }
  };

  return (
    <div className="liked-rings__image-container">
      {likedRingsParent.length === 0 && isLoading ? (
        <Spinner theme="light" />
      ) : (
        likedRingsParent.map(
          ({
            id,
            url,
            salesUrl,
            currency,
            salesPrice,
            numberOfLikes,
            waitingForApproval,
            uploadedBy,
          }) => (
            <ImageBox
              key={id}
              {...{
                id,
                url: Array.isArray(url) ? url[0] : url,
                salesUrl: salesUrl ? salesUrl : "",
                currency: currency ? currency : "",
                salesPrice: salesPrice ? salesPrice : 0,
                uploadedBy,
                numberOfLikes,
                waitingForApproval,
                showLikes: false,
                showLikeButton: isGuest ? false : true,
                removeLike: (event) => removeLike(event, id),
              }}
            ></ImageBox>
          ),
        )
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setLikedRingsState: (rings: IRing[]) => dispatch(setLikedRings(rings)),
});

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(LikedRingsContainer);
