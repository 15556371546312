import React, { ReactNode } from "react";
import "./base-page.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";

// components
import Header from "../header/header";
import Footer from "../footer/footer";

interface BasePageProps {
  children: ReactNode;
  serviceIsDown?: boolean;
  currentUser: any;
  showFilter?: boolean;
  showShare?: boolean;
  showStatistics?: boolean;
  likedRingUrls?: any;
  showLikedRingUrls?: boolean;
}

const BasePage: React.FC<BasePageProps> = ({
  children,
  currentUser,
  serviceIsDown = false,
  ...props
}) => {
  return (
    <div className="base-page">
      {serviceIsDown && (
        <div className="service-interruption">
          We are currently experiencing service disruptions :,&#40; if you are having issues please
          check back later!
        </div>
      )}

      <Header {...props} />
      {children}
      {currentUser && <Footer />}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const connector = connect(mapStateToProps);

export default connector(BasePage);
