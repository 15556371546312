import { IRing } from "../../utils/interfaces";
import { RingsActionTypes } from "./rings.types";

export const setAllRings = (rings: IRing[]) => ({
  type: RingsActionTypes.SET_ALL_RINGS,
  payload: rings,
});

export const setLikedRingUrls = (ringUrls: string[]) => ({
  type: RingsActionTypes.SET_LIKED_RING_URLS,
  payload: ringUrls,
});

export const addLikedRingUrl = (ringUrl: string) => ({
  type: RingsActionTypes.ADD_LIKED_RING_URL,
  payload: ringUrl,
});

export const setLikedRings = (rings: IRing[]) => ({
  type: RingsActionTypes.SET_LIKED_RINGS,
  payload: rings,
});

export const addLikedRing = (ring: IRing) => ({
  type: RingsActionTypes.ADD_LIKED_RING,
  payload: ring,
});

export const setLastFetchedRing = (ring: IRing) => ({
  type: RingsActionTypes.SET_LAST_FETCHED_RING,
  payload: ring,
});
