import { createSelector } from "reselect";

const selectRings = (state: any) => state.rings;

export const selectAllRings = createSelector([selectRings], (rings) => rings.allRings);

export const selectLikedRingUrls = createSelector([selectRings], (rings) => rings.likedRingUrls);

export const selectLikedRings = createSelector([selectRings], (rings) => rings.likedRings);

export const selectLastFetchedRing = createSelector(
  [selectRings],
  (rings) => rings.lastFetchedRing,
);

export const selectRandomSeed = createSelector([selectRings], (rings) => rings.randomSeed);
