import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// components
import BasePage from "../../components/base-page/base-page";
import RingDetailedView from "../../components/ring-detailed-view/ring-detailed-view";
import { getRingWithId } from "../../utils/firebase.utils";
import { IRing } from "../../utils/interfaces";

const Ring = () => {
  const location = useLocation();
  const { ring } = location.state || {};
  const [ringFromUrl, setRingFromUrl] = useState<IRing | null>(null);

  useEffect(() => {
    const fetchRing = async () => {
      if (ring) return;
      if (location.pathname && location.pathname.split("/").length > 2) {
        const ringId = location.pathname.split("/")[2];

        try {
          // if getRingWithId(ringId) is async, you need to await it
          const documentData = await getRingWithId(ringId);

          if (documentData) {
            // Cast documentData to a Ring type
            const ring = documentData as IRing;

            setRingFromUrl(ring);
          }
        } catch (error) {
          console.error("Failed to set ring from URL: ", error);
        }
      }
    };

    fetchRing();
  }, [location, ring]);

  return (
    <BasePage>
      <RingDetailedView ring={ring ? ring : ringFromUrl} close={() => {}}></RingDetailedView>
    </BasePage>
  );
};

export default Ring;
