import React, { ReactComponentElement } from "react";
import "./login-button.scss";

import { ReactComponent as GoogleIcon } from "../../assets/google.svg";

interface ILoginButton {
  onClick?: any
  children: React.ReactNode
  isGoogleSignIn?: boolean
  type?: "button" | "submit" | "reset" | undefined
}

const LoginButton = ({ children, isGoogleSignIn, ...otherProps }: ILoginButton) => (
  <div>
    {isGoogleSignIn ? (
      <button className="google-sign-in login-button" {...otherProps}>
        <GoogleIcon className="icon" />
        {children}
      </button>
    ) : (
      <button className="login-button" {...otherProps}>
        {children}
      </button>
    )}
  </div>
);

export default LoginButton;
