import React, { useState, useEffect } from "react";

import "./spinner.scss";
import { useTranslation } from "react-i18next";
interface SpinnerProps {
  theme?: "light" | "dark";
}

const Spinner: React.FC<SpinnerProps> = ({ theme = "light" }) => {
  const { t } = useTranslation();
  const phrases = [
    t("spinner.sprinkling some sparkle"),
    t("spinner.polishing your picks"),
    t("spinner.glamour on the way"),
    t("spinner.adding some bling"),
    t("spinner.shining up your selection"),
    t("spinner.finding your perfect match"),
    t("spinner.glimmering and glittering"),
    t("spinner.crafting your dream rings"),
    t("spinner.magic in progress"),
    t("spinner.love is in the details"),
  ];

  const [currentPhrase, setCurrentPhrase] = useState(phrases[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prevPhrase) => {
        const currentIndex = phrases.indexOf(prevPhrase);
        const nextIndex = (currentIndex + 1) % phrases.length;
        return phrases[nextIndex];
      });
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`spinner-overlay ${theme === "dark" ? "dark-theme" : "light-theme"}`}>
      <div className="spinner-container" />
      <div className="spinner-text">{currentPhrase}</div>
    </div>
  );
};

export default Spinner;
