import React, { useEffect, useState } from "react";
import "./statistics.scss";

// components
import CaratSize from "../carat-size/carat-size";
import CustomListItem from "../custom-list-item/custom-list-item";

// utils
import { sortArrayOfNumbers } from "../../utils/sorting.utils";
import { metalTagsData } from "../../utils/constants.utils";
import { IRing } from "../../utils/interfaces";
import Spinner from "../spinner/spinner";
import { useTranslation } from "react-i18next";

interface IStatistics {
  likedRings: IRing[];
  isLoading?: boolean;
}

type ShapeCountArrayItem = {
  count: number;
  shape: string;
  percentage: number;
};

type ShapeCount = {
  [key: string]: number;
};

const Statistics = ({ likedRings, isLoading = true }: IStatistics) => {
  const [averageStoneSize, setAverageStoneSize] = useState(1);
  const [topShapesList, setTopShapesList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const convertMmToCarat = (mm: number) => {
      switch (mm) {
        case 5:
          return 0.5;
        case 10:
          return 1.6;
        case 15:
          return 3.1;
        case 20:
          return 5;
        default:
          return mm;
      }
    };

    const listOfStoneSizes = likedRings.map(
      (ring) =>
        (typeof ring.actualStoneSize === "string" ? parseFloat(ring.actualStoneSize) : 0) ||
        (typeof ring.mainStoneSize === "number" ? ring.mainStoneSize : 0),
    );
    const convertedSizes = listOfStoneSizes.map((mm) => convertMmToCarat(mm));
    const sumOfStoneSizes = convertedSizes.reduce((a, b) => a + b, 0);
    const average = parseFloat((sumOfStoneSizes / listOfStoneSizes.length).toFixed(1));
    if (average > 0) setAverageStoneSize(average);

    const listOfStoneShapes = likedRings.flatMap((ring) =>
      Array.isArray(ring.stoneCut) ? ring.stoneCut : [ring.stoneCut],
    );

    const excludedTags: string[] = metalTagsData.map((tag) => tag.value);

    const filteredShapes: string[] = listOfStoneShapes.filter(
      (shape) => !excludedTags.includes(shape),
    );

    const shapesCount: ShapeCount = filteredShapes.reduce(
      (reducerObject: ShapeCount, shape: string) => {
        if (!reducerObject[shape]) {
          reducerObject[shape] = 1;
        } else {
          // shape exists, so increment its count
          reducerObject[shape]++;
        }

        return reducerObject; // return the modified object to be used as accumulator in the next iteration
      },
      {},
    );

    const totalShapes = Object.values(shapesCount).reduce((sum, count) => sum + count, 0);

    const shapesCountArray: ShapeCountArrayItem[] = [];

    Object.keys(shapesCount).map((shape) =>
      shapesCountArray.push({
        count: shapesCount[shape],
        shape: shape,
        percentage: shapesCount[shape] / totalShapes,
      }),
    );

    setTopShapesList(sortArrayOfNumbers(shapesCountArray));
  }, [likedRings]);
  return (
    <div className="statistics">
      <div className="statistics__container">
        <h2 className="statistics__title">{t("statistics.Favourite carat")}</h2>
        <CaratSize caratSize={averageStoneSize} />
      </div>
      <div className="statistics__container">
        <h2 className="statistics__title">{t("statistics.Favourite cuts")}</h2>
        {topShapesList.length === 0 && isLoading ? (
          <Spinner theme="light" />
        ) : (
          topShapesList
            .slice(0, 3)
            .map((shape: ShapeCountArrayItem, index) => <CustomListItem key={index} {...shape} />)
        )}
      </div>
    </div>
  );
};

export default Statistics;
