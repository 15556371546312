import "./save-to-collection.scss";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { useTranslation } from "react-i18next";
import SaveToCollectionItem from "../save-to-collection-item/save-to-collection-item";
import { Link } from "react-router-dom";

interface SaveToCollectionModalProps {
  close: () => void;
  currentRingId: string;
  setIsSaved: () => void;
}

const SaveToCollectionModal = ({
  close,
  currentRingId,
  setIsSaved,
}: SaveToCollectionModalProps) => {
  const currentUser = useSelector(selectCurrentUser);
  const { t } = useTranslation();

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <div className="save-to-collection-modal__overlay" onClick={handleBackgroundClick}>
      <div className="save-to-collection-modal">
        <div className="save-to-collection-modal__content">
          <div className="save-to-collection-modal__header">
            {t("saveToCollection.Save to collection")}
          </div>
          <div className="save-to-collection-modal__content__list">
            {currentUser ? (
              currentUser.lists?.length > 0 ? (
                currentUser.lists?.map((listId: string) => (
                  <SaveToCollectionItem
                    key={listId}
                    listId={listId}
                    ringId={currentRingId}
                    close={close}
                    setIsSaved={setIsSaved}
                  />
                ))
              ) : (
                <span className="save-to-collection-modal__need-login">
                  No collections found add one{" "}
                  <Link to={`/profile/${currentUser?.id}#collections`}>here</Link>
                </span>
              )
            ) : (
              <span className="save-to-collection-modal__need-login">
                <Link to={"/login"}>Login</Link> to add a ring to a collection
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveToCollectionModal;
