/**
 * Returns filtered objects in array with props that matches props in filters
 * @param {array} array
 * @param {object} filters
 * @returns {array}
 */

export const ObjectsInArrayFilter = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array.filter((object) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;

      if (Array.isArray(object[key])) {
        // Handle array of strings
        return object[key].some((value) => filters[key].includes(value));
      } else {
        // Handle single string
        return filters[key].includes(object[key]);
      }
    });
  });
};
