import React, { useEffect, useState } from "react";
import "./profile-image-holder.scss";

// svgs
import { ReactComponent as AddPhotoIcon } from "../../assets/add_photo.svg";

// utils
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, updatePropForUserWithId } from "../../utils/firebase.utils";

const ProfileImageHolder = ({ isEdit, userId, userProfileImage }) => {
  const [imageUrl, setImageUrl] = useState("/profile-placeholder.png");
  const [imageFile, setImageFile] = useState();

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/tiff" &&
      file.type !== "image/webp"
    )
      return alert(`not an image, the image file is a ${typeof file}`);

    setImageFile(file);
    // turn image into data url
    imageIntoDataUrl(file);
  };

  const imageIntoDataUrl = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      // This sends the data url to the img tag in html
      // And Sends imageUrl to State, should be turned into an Array for more images
      setImageUrl(reader.result);
    };
  };

  const handleFireBaseUpload = (imageAsFile) => {
    const currentTime = Date.now();
    const imageName = `${currentTime}-${imageAsFile.name}`;

    const storageRef = ref(storage, `/profile/${imageName}`);

    const uploadTask = uploadBytesResumable(storageRef, imageAsFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // const progress =
        //  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
          default:
            console.error("unhandled state update");
        }
      },
      (error) => {
        console.error("Error uploading image", error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          updatePropForUserWithId("profileImage", downloadURL, userId);
          resetAllFields();
        });
      },
    );
  };

  const resetAllFields = () => {
    setImageFile(null);
  };

  useEffect(() => {
    userProfileImage ? setImageUrl(userProfileImage) : setImageUrl("/profile-placeholder.png");
  }, [userProfileImage]);
  return (
    <div className="profile-image-holder">
      <div className="profile-image-holder__holder" style={{ backgroundImage: `url(${imageUrl})` }}>
        {isEdit &&
          (imageFile ? (
            <button
              className="profile-image-holder__save-button"
              onClick={() => handleFireBaseUpload(imageFile)}
            >
              save
            </button>
          ) : (
            <>
              <label className="profile-image-holder__button" htmlFor="file-input">
                <AddPhotoIcon className="profile-image-holder__icon" />
              </label>
              <input id="file-input" className="hidden" type="file" onChange={handleUploadClick} />
            </>
          ))}
      </div>
    </div>
  );
};

export default ProfileImageHolder;
