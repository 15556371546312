import { RingsActionTypes } from "./rings.types";

const INITIAL_STATE = {
  allRings: [],
  likedRings: [],
  likedRingUrls: [],
  lastFetchedRing: null,
  randomSeed: Math.random(),
};

const ringsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RingsActionTypes.SET_ALL_RINGS:
      return {
        ...state,
        allRings: action.payload,
      };
    case RingsActionTypes.SET_LIKED_RINGS:
      return {
        ...state,
        likedRings: action.payload,
      };
    case RingsActionTypes.ADD_LIKED_RING:
      return {
        ...state,
        likedRings: [...state.likedRings, action.payload],
      };
    case RingsActionTypes.SET_LIKED_RING_URLS:
      return {
        ...state,
        likedRingUrls: action.payload,
      };
    case RingsActionTypes.ADD_LIKED_RING_URL:
      return {
        ...state,
        likedRingUrls: [...state.likedRingUrls, action.payload],
      };
    case RingsActionTypes.SET_LAST_FETCHED_RING:
      return {
        ...state,
        lastFetchedRing: action.payload,
      };
    default:
      return state;
  }
};

export default ringsReducer;
