import React from "react";
import "./overlay-modal.scss";

class OverlayModal extends React.Component {
  componentDidMount = () => {
    document.addEventListener("click", this.handleClick, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleClick, false);
  };

  handleClick = (event) => {
    const { close } = this.props;
    const classString = event.target.className.toString();
    if (!classString) return;
    const classes = classString.split(" ");
    if (classes[0] === "modal-overlay") close(event);
  };

  render() {
    const { children } = this.props;
    return (
      <div className="modal-overlay" name="back">
        {children}
      </div>
    );
  }
}

OverlayModal.defaultProps = {
  close: () => {},
};

export default OverlayModal;
