import React, { useState } from "react";
import "./modals.scss";
import { Link } from "react-router-dom";

// components
import FormInput from "../form-input/form-input";

// svg
import { ReactComponent as RingIcon } from "../../assets/ring.svg";

// functions import
import { createSavedImagesMail } from "../../utils/firebase.utils";
import { useTranslation } from "react-i18next";

const SaveImages = ({ likedRingUrls, imagesSaved }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const {t} = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      return;
    }
    setError(false);
    createSavedImagesMail({
      email,
      likedRingUrls,
    });
    imagesSaved();
  };

  return (
    <div className="modal">
      <div className="save-modal modal-background">
        <RingIcon />
        <p>{t("saveImages.Where should we send your saved rings?")}</p>
        <form onSubmit={handleSubmit}>
          <FormInput
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="email"
            autoComplete="email"
            label={t("saveImages.Email")}
            value={email}
            black={true}
            required
          ></FormInput>
          {error ? <p className="error">{t("saveImages.missing email!")}</p> : <p className="error"> </p>}

          <button className="primary inverted">{t("saveImages.Share")} {likedRingUrls.length} {t("saveImages.rings")}</button>
          <p className="terms">
            {t("saveImages.By entering your email above you agree to our")} <Link to="/terms">{t("saveImages.terms")}</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SaveImages;
