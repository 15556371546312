import "./promo-body.scss"
import { useTranslation } from 'react-i18next';

// logos
import { ReactComponent as TiffanyLogo } from "../../assets/tiffany_logo.svg";
import vanbruunLogo from '../../assets/vanbruun_logo.png';
import { ReactComponent as KataokaLogo } from "../../assets/kataoka_logo.svg";
import { ReactComponent as BrilliantEarthLogo } from "../../assets/brilliant_earth_logo.svg";
import { ReactComponent as CapucinneLogo } from "../../assets/capucinne_logo.svg";



interface IPromoBody {
}

const PromoBody = (props: IPromoBody) => {
    const {t} = useTranslation();

  return (
    <div className="promo-body__container">
        <h1>{t("promoBody.Discover over 5000 unique rings from top brands - get your inspiration here!")}</h1>
        <div className="promo-body__logos ">
            <TiffanyLogo className="promo-body__logo"/>
            <img src={vanbruunLogo} className="promo-body__logo"/>
            <KataokaLogo className="promo-body__logo"/>
            <BrilliantEarthLogo className="promo-body__logo"/>
            <CapucinneLogo className="promo-body__logo"/>
        </div>
    </div>
  );
};

export default PromoBody;
