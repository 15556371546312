import { FunctionComponent, useEffect, useState } from "react";
import "./save-to-collection-item.scss";
import {
  getListWithId,
  getNumberOfRingIdsForList,
  getRingWithId,
  saveRingWithIdToListId,
} from "../../utils/firebase.utils";

interface ISaveToCollectionItemProps {
  listId: string;
  ringId: string;
  close: () => void;
  setIsSaved: () => void;
}

const SaveToCollectionItem: FunctionComponent<ISaveToCollectionItemProps> = ({
  listId,
  ringId,
  close,
  setIsSaved,
}) => {
  const [collection, setCollection] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionData = await getListWithId(listId);

        const firstRingIds = await getNumberOfRingIdsForList(listId, 1);
        if (firstRingIds.length > 0) {
          const firstRingData = await getRingWithId(firstRingIds[0]);

          if (firstRingData === null) return;

          const url = Array.isArray(firstRingData.url) ? firstRingData.url[0] : firstRingData.url;

          setCollection({ ...collectionData, url });
        } else setCollection(collectionData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [listId]);

  const saveRingToList = async () => {
    await saveRingWithIdToListId(ringId, listId);
    setIsSaved();
    close();
  };

  const emptyWhiteImage =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' fill='%23fff'/%3E";

  return (
    <>
      {collection && (
        <div
          onClick={() => saveRingToList()}
          key={collection.id}
          className="save-to-collection-modal__item"
        >
          <img src={collection.url ? collection.url : emptyWhiteImage} alt={collection.name} />
          <span className="item-name">{collection.name}</span>
        </div>
      )}
    </>
  );
};

export default SaveToCollectionItem;
