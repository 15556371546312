import React from "react";
import "./sign-up.scss";

// Components
import SignUp from "../../components/sign-up-email/sign-up";
import BasePage from "../../components/base-page/base-page";

const SignUpPage: React.FC = () => {
  return (
    <BasePage>
      <div className="sign-up-page">
        <div className="sign-up-page__ring-bg">
          <div className="sign-up-page__dark-overlay">
            <div className="sign-in-and-sign-up">
              <SignUp />
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default SignUpPage;
