import { useState, useEffect, useRef, FC } from "react";
import "./upload-image-form.scss";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import ImageUploadArea from "../upload-image-area/upload-image-area";
import FormInput from "../form-input/form-input";
import Creatable from "react-select/creatable";
import Select from "react-select";
import { getListOfCollection } from "../../utils/firebase.utils";
import {
  NOT_LOGGED_IN,
  stoneCutTagsData,
  stoneTypeTagsData,
  colorTagsData,
  styleTagsData,
  metalTagsData,
  typesData,
  settingTagsData,
} from "../../utils/constants.utils";
import { ReactComponent as AddPhotoIcon } from "../../assets/add_photo.svg";
import { ReactComponent as CadIcon } from "../../assets/cad.svg";

interface UploadImageFormProps {
  setStateImageUrl: (urls: string[]) => void;
  setStateImageFile: (files: File[]) => void;
  stateImageFile: File[];
  setStoneCut: (cuts: string[]) => void;
  stoneCut: string[];
  setMainStoneSize: (size: number | null) => void;
  mainStoneSize: number | null;
  stateImageUrl: string[];
  setSalesPrice: (price: number) => void;
  setSalesUrl: (url: string) => void;
  salesUrl: string;
  salesPrice: number;
  setType: (type: string) => void;
  setUploadAsUser: (user: { id: string; name: string }) => void;
  handleUpload: () => void;
  setStateCadUrl: (urls: string[]) => void;
  setStateCadFile: (files: File[]) => void;
  stateCadUrl: string[];
  stateCadFile: File[];
}

const UploadImageForm: FC<UploadImageFormProps> = ({
  setStateImageUrl,
  setStateImageFile,
  stateImageFile,
  setStoneCut,
  stoneCut,
  setMainStoneSize,
  mainStoneSize,
  stateImageUrl,
  setSalesPrice,
  setSalesUrl,
  salesUrl,
  salesPrice,
  setType,
  setUploadAsUser,
  handleUpload,
  setStateCadUrl,
  setStateCadFile,
  stateCadUrl,
  stateCadFile,
}) => {
  const [forSale, setForSale] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const [selectedCadFile, setSelectedCadFile] = useState<File | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const userOptions = useRef<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (currentUser?.access === "admin") {
      getListOfCollection("users").then((result) => {
        userOptions.current = [
          { value: NOT_LOGGED_IN, label: NOT_LOGGED_IN },
          ...result.map(({ id, displayName = "Unknown User" }) => ({
            value: id,
            label: displayName,
          })),
        ];
      });
    }
  }, [currentUser?.access]);

  const imageResetHandler = (imageUrlToRemove: string) => {
    const updatedImageUrls = stateImageUrl.filter((url) => url !== imageUrlToRemove);
    const updatedImageFiles = stateImageFile.filter(
      (_, index) => stateImageUrl[index] !== imageUrlToRemove,
    );

    setStateImageFile(updatedImageFiles);
    setStateImageUrl(updatedImageUrls);
    setSelectedImageFile(null);
  };

  const cadResetHandler = (cadUrlToRemove: string) => {
    const updatedCadUrls = stateCadUrl.filter((url) => url !== cadUrlToRemove);
    const updatedCadFiles = stateCadFile.filter(
      (_, index) => stateCadUrl[index] !== cadUrlToRemove,
    );

    setStateCadFile(updatedCadFiles);
    setStateCadUrl(updatedCadUrls);
    setSelectedCadFile(null);
  };

  return (
    <div className="upload-image-form">
      <div className="upload-image-form__container">
        <ImageUploadArea
          setStateImageUrl={setStateImageUrl}
          setStateImageFile={setStateImageFile}
          selectedFile={selectedImageFile}
          inputId="image-input"
        >
          <AddPhotoIcon className="upload-image-area__icon" />
        </ImageUploadArea>
        <div className="vl" />
        <ImageUploadArea
          setStateImageUrl={setStateCadUrl}
          setStateImageFile={setStateCadFile}
          selectedFile={selectedCadFile}
          inputId="cad-input"
        >
          <CadIcon className="upload-image-area__icon" />
        </ImageUploadArea>
      </div>
      {stateImageUrl.length > 0 && (
        <div className="upload-image-form__card-container">
          <label className="form-input-label">Ring images</label>
          <div className="upload-image-form__card-list">
            {stateImageUrl.map((imageUrl, index) => (
              <div className="upload-image-form__card" key={index}>
                <div
                  className="image-card"
                  style={{ backgroundImage: "url(" + imageUrl + ")" }}
                  onClick={() => imageResetHandler(imageUrl)}
                />
                <div className="delete" />
              </div>
            ))}
          </div>
        </div>
      )}
      {stateCadUrl.length > 0 && (
        <div className="upload-image-form__card-container">
          <label className="form-input-label">Cad drawings</label>
          <div className="upload-image-form__card-list">
            {stateCadUrl.map((cadUrl, index) => (
              <div className="upload-image-form__card" key={index}>
                <div
                  className="image-card"
                  style={{ backgroundImage: "url(" + cadUrl + ")" }}
                  onClick={() => cadResetHandler(cadUrl)}
                />
                <div className="delete" />
              </div>
            ))}
          </div>
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUpload();
        }}
        autoComplete="off"
      >
        <div className="form-group">
          <Select
            id="type-select"
            options={typesData}
            onChange={(e) => setType(e?.value || "")}
            defaultValue={typesData[0]}
          />
        </div>
        <div className="form-group">
          <Creatable
            id="cut-select"
            isMulti
            options={[
              ...stoneCutTagsData,
              ...stoneTypeTagsData,
              ...colorTagsData,
              ...styleTagsData,
              ...metalTagsData,
              ...settingTagsData,
            ]}
            onChange={(e) => setStoneCut(e.map((cut) => cut.value))}
            required
            placeholder="cut, metal, etc*"
          />
        </div>
        {!stoneCut.includes("noStone") && (
          <div>
            <FormInput
              name="number"
              type="number"
              label="Main stone size (ct)*"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMainStoneSize(parseFloat(e.target.value))
              }
              min={0}
              step={0.01}
              value={mainStoneSize === null ? "" : mainStoneSize} // Handle the default value for display
              black={true}
              required
            />
          </div>
        )}
        {currentUser?.access === "admin" && (
          <div className="form-group">
            <label className="form-input-label" htmlFor="user-select">
              Upload as user
            </label>
            <Select
              id="user-select"
              options={userOptions.current}
              onChange={(e) => setUploadAsUser({ id: e?.value || "", name: e?.label || "" })}
              defaultValue={{ value: NOT_LOGGED_IN, label: NOT_LOGGED_IN }}
              isLoading={userOptions.current.length === 0}
            />
          </div>
        )}
        {stateCadUrl.length > 0 && stateImageUrl.length === 0 && (
          <span className="red">
            Please note: A photograph of the ring is required in addition to the CAD file; CAD alone
            is not sufficient.
          </span>
        )}
        <>
          <div>
            <FormInput
              name="number"
              type="number"
              label="Sale price ($) (optional)"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSalesPrice(parseFloat(e.target.value))
              }
              value={salesPrice}
              min={0}
              step={1}
              black={true}
            />
          </div>
          <div>
            <FormInput
              name="url"
              type="text"
              label="url (optional)"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSalesUrl(e.target.value)}
              value={salesUrl}
              black={true}
            />
          </div>
        </>
        <input
          type="submit"
          className="button primary inverted"
          disabled={!stateImageUrl.length}
          value="Upload"
        />
      </form>
    </div>
  );
};

export default UploadImageForm;
