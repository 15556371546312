import React, { useState, useEffect, useRef } from "react";
import "./landing.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

// components
import BasePage from "../../components/base-page/base-page";
import RingSwipe from "../../components/ring-swipe/ring-swipe";
import Spinner from "../../components/spinner/spinner.tsx";
import OverlayModal from "../../components/overlay-modal/overlay-modal";
import SaveImages from "../../components/modals/save-images";
import ThankYou from "../../components/modals/thank-you";
import Sidebar from "../../components/sidebar/sidebar";
import SignIn from "../../components/sign-in/sign-in";

// utils
import {
  getLimitedNumberAndFilterCollection,
  getLimitedNumberFromCollection,
  updateLastSignInForUserId,
} from "../../utils/firebase.utils";

// selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectSidebarHidden } from "../../redux/sidebar/sidebar.selectors";
import { toggleSidebarHidden } from "../../redux/sidebar/sidebar.actions";
import {
  selectAllRings,
  selectLastFetchedRing,
  selectLikedRingUrls,
  selectRandomSeed,
} from "../../redux/rings/rings.selectors";
import { setAllRings, setLastFetchedRing } from "../../redux/rings/rings.actions";
import { caratData, getValuesAsObject, stoneCutTagsData, stoneTypeTagsData, colorTagsData, styleTagsData, metalTagsData, settingTagsData } from "../../utils/constants.utils";
import { selectModalHidden } from "../../redux/modal/modal.selectors";
import { toggleModalHidden } from "../../redux/modal/modal.actions";
import { ObjectsInArrayFilter } from "../../utils/filter.utils";
import { shuffle } from "../../utils/sorting.utils";
import PromoBody from "../../components/promo-body/promo-body";
import { getKeyByValue, getKeysByValue } from "../../utils/object.utils";

// constants
const TABLET_SIZE = 768;

const InspoPage = ({
  currentUser,
  sidebarHidden,
  toggleSidebar,
  ringsFromState,
  setRingsState,
  modalHidden,
  toggleModalHidden,
  lastFetchedRingState,
  setLastFetchedRingState,
  randomSeedState,
}) => {
  const [rings, setRings] = useState([]);
  const [filters, setFilters] = useState({
    mainStoneSize: getValuesAsObject(caratData),
    stoneCut: getValuesAsObject([...stoneCutTagsData, ...stoneTypeTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData]),
  });
  const [isSent, setIsSent] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > TABLET_SIZE);
  let navigate = useNavigate();
  const [ringsFinishedLoading, setRingsFinishedLoading] = useState(false);
  const [NoMoreRingsToFetch, setNoMoreRingsToFetch] = useState(false);
  const prevFiltersRef = useRef(filters);

  // **************** Collect all keys for filter  ****************
  // This function collects ALL keys that have true as a value,
  // then creates a new obj with arrays for each filter containing true keys.
  const getTrueKeys = () => {
    let trueKeys = {
      mainStoneSize: [],
      stoneCut: [],
    };
    const { mainStoneSize, stoneCut } = filters;

    for (let mainStoneSizeKey in mainStoneSize) {
      if (mainStoneSize[mainStoneSizeKey]) trueKeys.mainStoneSize.push(parseInt(mainStoneSizeKey));
    }
    for (let stoneCutKey in stoneCut) {
      if (stoneCut[stoneCutKey]) trueKeys.stoneCut.push(stoneCutKey);
    }
    return trueKeys;
  };

  const fetchRings = async () => {
    const viewedRingsArray =
      currentUser && "viewedRings" in currentUser
        ? currentUser.viewedRings
        : localStorage.getItem("alreadyRemovedv2")
          ? JSON.parse(localStorage.getItem("alreadyRemovedv2"))
          : [];

    try {
      let result;
      // if there are less than 10 filters selected and no alterations to the mainStoneSize filter fetch rings with the getLimitedNumberAndFilterCollection function
      if (
        Object.values(getTrueKeys().stoneCut).filter((value) => value).length < 10 &&
        Object.values(getTrueKeys().mainStoneSize).filter((value) => value).length === 4
      ) {
        result = await getLimitedNumberAndFilterCollection(
          50,
          viewedRingsArray,
          getTrueKeys(),
          lastFetchedRingState,
          randomSeedState,
        );
      } else {
        result = await getLimitedNumberFromCollection(
          50,
          viewedRingsArray,
          getTrueKeys(),
          lastFetchedRingState,
          randomSeedState,
        );
      }

      if (!result || result.allRings.length === 0) {
        console.log("No rings fetched.");
        setNoMoreRingsToFetch(true);
      } else {
        // filter out rings that are already in state
        const filteredAllRings = result.allRings.filter((ring) => {
          return !ringsFromState.some((ringFromState) => ringFromState.id === ring.id);
        });

        setRingsState([...ringsFromState, ...filteredAllRings]);

        if (result.rings.length < 40) {
          setNoMoreRingsToFetch(true);
        } else {
          setNoMoreRingsToFetch(false);
        }
      }

      // filter out rings that are already in state
      const filteredRings = result.rings.filter((ring) => {
        return !ringsFromState.some((ringFromState) => ringFromState.id === ring.id);
      });

      const ringsToSet = filteredRings
        ? [...ObjectsInArrayFilter(shuffle(ringsFromState), getTrueKeys()), ...filteredRings]
        : [...ObjectsInArrayFilter(shuffle(ringsFromState), getTrueKeys())];

      setRings(ringsToSet);

      if (result.lastDoc !== 0) {
        setLastFetchedRingState(result.lastDoc);
      }
      setRingsFinishedLoading(true);
    } catch (error) {
      console.error("Error fetching rings:", error);
      setRingsFinishedLoading(true);
    }
  };

  const filtersAreDifferent = (prevFilters, currentFilters) => {
    return JSON.stringify(prevFilters) !== JSON.stringify(currentFilters);
  };

  const getQueryParams = (url) => {
    const params = {};
    const urlObj = new URL(url);
    
    urlObj.searchParams.forEach((value, key) => {
        // Split the values by commas and store them as an array
        params[key] = value.split(',');
    });
    
    return params;
};

const updateFiltersFromUrl = (urlParamKey, filterKey) => {
  const filtersFromUrl = getQueryParams(window.location.href);

  const selectedItems = filtersFromUrl[urlParamKey] || [];

  if (selectedItems.length === 0) {
    return;
  }

  const filter = filters[filterKey];

  // Set all filter values to false if no false values exist in the object
  if (!getKeyByValue(filter, false)) {
    Object.keys(filter).forEach((key) => (filter[key] = false));
  }

  // Iterate through all selected items from the URL
  selectedItems.forEach((item) => {
    const trueKeys = getKeysByValue(filter, true);

    // Check if only one filter is true and matches the current item
    if (trueKeys.length === 1 && trueKeys[0] === item) {
      Object.keys(filter).forEach((key) => {
        filter[key] = key === item ? true : false;
      });
    } else {
      // Toggle the value of the item key in the filter object
      filter[item] = !filter[item];
    }
  });

  // Update the filters object with the new filter values
  setFilters((prevFilters) => ({
    ...prevFilters,
    [filterKey]: { ...filter },
  }));
};

// Use the reusable function to update specific filters
useEffect(() => {
  updateFiltersFromUrl('tags', 'stoneCut');
  updateFiltersFromUrl('stoneSize', 'mainStoneSize');
}, []);
  

  useEffect(() => {
    setRingsFinishedLoading(false);

    const shouldFetchRings =
      !rings || ringsFromState < 10 || filtersAreDifferent(prevFiltersRef.current, filters);

    if (shouldFetchRings) {
      fetchRings();
    } else {
      const ringsToSet = [...ObjectsInArrayFilter(ringsFromState, getTrueKeys())];
      setRings(ringsToSet);
      setRingsFinishedLoading(true);
    }

    prevFiltersRef.current = filters;
  }, [filters]);

  const handleOnSend = () => {
    setIsSent(true);
  };

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > TABLET_SIZE);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    if (!currentUser?.id) return;
    updateLastSignInForUserId(currentUser.id);
  }, [currentUser?.id]);

  const setAllFiltersToTrue = () => {
    const updatedFilters = {
      ...filters,
    };

    Object.keys(updatedFilters.mainStoneSize).forEach((size) => {
      updatedFilters.mainStoneSize[size] = true;
    });

    Object.keys(updatedFilters.stoneCut).forEach((shape) => {
      if (shape !== "cad") {
        updatedFilters.stoneCut[shape] = true;
      } else {
        updatedFilters.stoneCut[shape] = false;
      }
    });

    setFilters(updatedFilters);

    const newUrl = window.location.pathname;
    window.history.replaceState({}, '', newUrl);
  };

  return (
    <BasePage showFilter={true} showLikedRingUrls={true}>
      <div className="inspo-page">
        {!modalHidden && (
          <OverlayModal close={toggleModalHidden}>
            {!isSent ? (
              <SaveImages likedRingUrls imagesSaved={handleOnSend} />
            ) : (
              <ThankYou closeModal={toggleModalHidden} />
            )}
          </OverlayModal>
        )}
        <CSSTransition in={!sidebarHidden} timeout={200} classNames="fade" unmountOnExit>
          <OverlayModal close={toggleSidebar}>
            <Sidebar setFilters={setFilters} filters={filters} title="Filters" />
          </OverlayModal>
        </CSSTransition>

        <div className="inspo-page__container">
          <div className="inspo-page__card-container">
            {!ringsFinishedLoading ? (
              <Spinner theme="dark" />
            ) : (
              <RingSwipe
                fetchRings={fetchRings}
                NoMoreRingsToFetch={NoMoreRingsToFetch}
                ringsFromDb={rings}
                setAllFiltersToTrue={setAllFiltersToTrue}
              />
            )}
          </div>

          {!currentUser && isDesktop && <SignIn handleEmailClick={() => navigate("/login")} />}
        </div>
        
      </div>
      {!currentUser && <PromoBody />}
    </BasePage>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  sidebarHidden: selectSidebarHidden,
  ringsFromState: selectAllRings,
  modalHidden: selectModalHidden,
  likedRingUrls: selectLikedRingUrls,
  lastFetchedRingState: selectLastFetchedRing,
  randomSeedState: selectRandomSeed,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebarHidden()),
  setRingsState: (rings) => dispatch(setAllRings(rings)),
  toggleModalHidden: () => dispatch(toggleModalHidden()),
  setLastFetchedRingState: (ring) => dispatch(setLastFetchedRing(ring)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InspoPage);
