import React, { useEffect, useRef, useState } from "react";
import "./carat-size.scss";

import { ReactComponent as DiamondIcon } from "../../assets/diamond.svg";

const SLIDER_WIDTH = 300;

const CaratSize = ({ caratSize = 1 }) => {
  if (caratSize > 5) caratSize = 5;

  const labelRef = useRef();
  const [offsetWidth, setOffsetWidth] = useState(1);

  useEffect(() => {
    setOffsetWidth(labelRef.current.offsetWidth);
  }, [caratSize]);

  return (
    <div className="carat-size">
      <DiamondIcon className="carat-size__diamond-ledger left" />
      <div className="carat-size__bar">
        <DiamondIcon
          className="carat-size__diamond-thumb"
          style={{ left: (SLIDER_WIDTH * caratSize) / 5 - 14 }}
        />
        <div
          className="carat-size__progress"
          style={{ width: (SLIDER_WIDTH * caratSize) / 5 + "px" }}
        ></div>
        <label
          ref={labelRef}
          className="carat-size__label"
          style={{
            left: (SLIDER_WIDTH * caratSize) / 5 - offsetWidth / 2,
          }}
        >
          {caratSize} ct
        </label>
      </div>
      <DiamondIcon className="carat-size__diamond-ledger right" />
    </div>
  );
};

export default CaratSize;
