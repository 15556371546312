import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

let userLang = navigator.language || navigator.userLanguage;
userLang = userLang.split("-")[0];
if (userLang === "sv") {
  userLang = "sv";
} else {
  userLang = "en";
}

i18next.use(initReactI18next).init({
  lng: userLang,
  debug: true,
  resources: {
    en: {
      translation:require("./lang/en.json"),
    },
    sv: {
      translation:require("./lang/sv.json"),
    },
  },
});

