import { useEffect, useState } from "react";
import "./comment.scss";
import { IComment, ICurrentUser } from "../../utils/interfaces";
import { countTimeFromToday } from "../../utils/time.utils";
import { getUserWithId } from "../../utils/firebase.utils";
import Spinner from "../spinner/spinner";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CommentProps extends IComment {
  currentUser: ICurrentUser;
}

const Comment: React.FC<CommentProps> = ({
  message,
  createdOn,
  id,
  authorId,
  onReply,
  className,
  currentUser,
}) => {
  const [user, setUser] = useState<ICurrentUser | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser.id === authorId) return setUser(currentUser);
    getUserWithId(authorId)
      .then((user: ICurrentUser) => {
        setUser(user);
      })
      .catch((error: string) => {
        console.error("Failed to get user: ", error);
      });
  }, [currentUser, authorId]);

  return (
    <div className={className ? `comment ${className}` : "comment"}>
      {user ? (
        <div className="comment__user-avatar">
          <img
            className="comment__user-avatar-image"
            src={user.profileImage ? user.profileImage : "/profile-placeholder.png"}
            alt={user?.displayName}
          />
        </div>
      ) : (
        <Spinner theme="light" />
      )}
      <div className="comment__details">
        <div>
          <Link to={`/profile/${user?.id}`} className="comment__user-name">
            {user?.displayName}
          </Link>
          <span className="comment__date">{countTimeFromToday(createdOn)}</span>
        </div>
        <span className="comment__text">{message}</span>
        {currentUser?.id !== authorId && (
          <button
            onClick={() => onReply && id && onReply(authorId, id)}
            className="text-button comment__reply"
          >
            {t("comment.Reply")}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(Comment);
