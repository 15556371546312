import React from "react";
import "./terms-of-service.scss";

// components
import Terms from "../../components/terms/terms";
import BasePage from "../../components/base-page/base-page";

const TermsOfService = () => {
  return (
    <BasePage>
      <div className="terms-of-service">
        <Terms />
      </div>
    </BasePage>
  );
};

export default TermsOfService;
