import { ReactNode, SyntheticEvent } from "react";
import "./upload-image-area.scss";

interface iImageUploadArea {
  setStateImageUrl: any;
  setStateImageFile: any;
  selectedFile: any;
  inputId: string;
  children: ReactNode;
}

const ImageUploadArea = ({
  setStateImageUrl,
  setStateImageFile,
  selectedFile,
  inputId,
  children,
}: iImageUploadArea) => {
  const handleUploadClick = (event: any) => {
    const filesArray = Array.from(event.target.files);

    setStateImageFile((prevState: any) => [...prevState, ...filesArray]);

    filesArray.forEach((file) => {
      imageIntoDataUrl(file);
    });
  };

  const imageIntoDataUrl = (file: any) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setStateImageUrl((prevState: Array<string>) => [...prevState, reader.result]);
    };

    reader.readAsDataURL(file);
  };
  return (
    <div className="upload-image-area">
      <label className="upload-image-area__button" htmlFor={inputId}>
        {children}
      </label>
      <input
        className="upload-image-area__file-input"
        id={inputId}
        type="file"
        onChange={handleUploadClick}
        value={selectedFile ? selectedFile.name : ""}
        accept="image/*"
      />
    </div>
  );
};

export default ImageUploadArea;
