import React from "react";
import "./sidebar.scss";
import { connect } from "react-redux";

// svgs
import { ReactComponent as Back } from "../../assets/back.svg";

// firebase
import { auth } from "../../utils/firebase.utils";

// actions
import { toggleSidebarHidden } from "../../redux/sidebar/sidebar.actions";

// components
import Filter from "./filter";
import Statistics from "./statistics";

const Sidebar = ({ toggleSidebar, title, ...props }) => {
  return (
    <div className="sidebar">
      <div className="sidebar_header">
        <Back className="back-icon" onClick={toggleSidebar} />
        <h3>{title}</h3>
      </div>
      <div className="sidebar__contents">
        {title === "Filters" && <Filter {...props} />}
        {title === "Your statistics" && <Statistics {...props} />}
      </div>
      <div className="sidebar__footer">
        <button className="primary inverted" onClick={() => auth.signOut() && toggleSidebar()}>
          Sign out
        </button>
        <span className="sidebar__report-text">
          Please help us keep Rinder clean by{" "}
          <a href="mailto:report@rinderr.com?subject=Report an issue with Rinder">reporting</a>{" "}
          issues
        </span>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebarHidden()),
});

export default connect(null, mapDispatchToProps)(Sidebar);
