import { FunctionComponent, useEffect, useState } from "react";
import "./collections-container.scss";
import { getListWithId, getUserWithId } from "../../utils/firebase.utils";
import CollectionCard from "../collection-card/collection-card";
import { IList } from "../../utils/interfaces";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import CreateCollectionModal from "../create-collection-modal/create-collection-modal";

interface ICollectionsContainerProps {
  userId: string;
  isItAGuest: boolean;
}

const CollectionsContainer: FunctionComponent<ICollectionsContainerProps> = ({
  userId,
  isItAGuest,
}) => {
  const [collections, setCollections] = useState<IList[]>([]);
  const [showCreateCollection, setShowCreateCollection] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      if (!userId) {
        return;
      }
      const user = await getUserWithId(userId);
      if (user && user.lists) {
        const userCollections = await Promise.all(
          user.lists.map((collectionId) => getListWithId(collectionId)),
        );
        setCollections(
          userCollections.filter((collection): collection is IList => collection !== null),
        );
      }
    };

    fetchCollections();
  }, [userId]);

  const addCollection = (newCollection: IList) => {
    setCollections((prev) => [...prev, newCollection]);
  };

  return (
    <div className="collections-container">
      {!isItAGuest && (
        <button onClick={() => setShowCreateCollection(true)} className="new-collection-button">
          <AddIcon className="box__add-icon" />
        </button>
      )}

      {collections.length > 0 &&
        collections.map((collection) => (
          <CollectionCard key={collection.id} collection={collection} />
        ))}
      {showCreateCollection && (
        <CreateCollectionModal
          close={() => setShowCreateCollection(false)}
          addCollection={(newCollection: IList) => addCollection(newCollection)}
        />
      )}
    </div>
  );
};

export default CollectionsContainer;
