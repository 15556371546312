import React from "react";
import ConfettiExplosion from "react-confetti-explosion";

const ThankYou = ({ closeModal }) => {
  return (
    <div className="modal">
      <div className="thank-modal modal-background">
        <ConfettiExplosion
          force={0.6}
          duration={5000}
          particleCount={200}
          height={1600}
          width={1600}
        />
        <h1 className="success-message">Your rings are on their way! 🎉</h1>
        <p>
          Is there something that could work better or didn't work as expected? Or something that
          you think is missing?
        </p>
        <span>Send feedback to</span>
        <a href="mailto:report@rinderr.com?subject=Feedback on Rinder">report@rinderr.com</a>
        <button onClick={closeModal} className="primary inverted">
          Done
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
