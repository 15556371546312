import React, { useEffect, useState } from "react";
import "./uploader-tag.scss";
import { useNavigate } from "react-router-dom";

// utils
import { getUserWithId } from "../../utils/firebase.utils";

// svg
import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";

const UploaderTag = ({ userId }) => {
  const [user, setUser] = useState({});
  let navigate = useNavigate();
  useEffect(() => {
    if (!userId) return;
    getUserWithId(userId).then((userObject) => setUser(userObject));
  }, [userId]);
  return userId ? (
    <div onClick={() => navigate(`profile/${userId}`)} className="uploader-tag">
      {user?.profileImage ? (
        <img alt="profile" className="profile-icon" src={user.profileImage} />
      ) : (
        <ProfileIcon className="profile-icon" />
      )}

      <span>{user?.displayName}</span>
    </div>
  ) : null;
};

export default UploaderTag;
