/**
 * returns array of keys that match the value
 * @param {object} object
 * @param {string, boolean, integer} value
 * @returns {array}
 */
export const getKeysByValue = (object, value) =>
  Object.keys(object).filter((key) => object[key] === value);

/**
 * returns first key that matches value
 * @param {object} object
 * @param {string, boolean, integer} value
 * @returns {string}
 */
export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);
