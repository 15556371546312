/**
 * composes multiple functions
 * @param {array} function
 * @returns {function}
 */

export const compose = (...fns) => {
  // Return a new function that takes an argument 'arg'
  return function (arg) {
    // Reduce the array of functions 'fns' from right to left
    return fns.reduceRight(
      // For each function 'fn', apply it to the accumulated value 'acc'
      (acc, fn) => fn(acc),
      // The initial value for the reduction is the argument 'arg'
      arg
    );
  };
};
