import { useState } from "react";
import "./login.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// Components
import SignIn from "../../components/sign-in/sign-in";
import SignInEmail from "../../components/sign-in-email/sign-in-email";
import BasePage from "../../components/base-page/base-page";

const Login = () => {
  const [isEmail, setIsEmail] = useState(false);
  const {t} = useTranslation();

  return (
    <BasePage>
      <div className="login">
        <div className="login__dark-overlay">
          <div className="sign-in-and-sign-up">
            {isEmail ? <SignInEmail /> : <SignIn handleEmailClick={() => setIsEmail(true)} />}
            <Link className="login__signup" to="/sign-up">
              {t('logIn.Create new account')}
            </Link>
          </div>
        </div>
      </div>
    </BasePage>
  );
};
export default Login;
