import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  arrayRemove,
  setDoc,
  arrayUnion,
  orderBy,
  startAfter,
  limit,
  Query,
  QueryDocumentSnapshot,
  DocumentData,
  OrderByDirection,
  deleteField,
  writeBatch,
  getCountFromServer,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { shuffle } from "./sorting.utils";
import { IClient, IComment, ICurrentUser, ILikeDislike, IList, IRing } from "./interfaces";
import {
  NOT_LOGGED_IN,
  NOT_LOGGED_IN_LIKE,
  RINGS_URL,
  RING_SUBCOLLECTIONS,
  devRingData,
} from "./constants.utils";
import { ObjectsInArrayFilter } from "./filter.utils";

const firebaseConfig = {
  apiKey: "AIzaSyDQF2KkOVQUg1HpsxMraXGjtWf2QBJ2Ls8",
  authDomain: "ring-swipe.firebaseapp.com",
  databaseURL: "https://ring-swipe-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ring-swipe",
  storageBucket: "ring-swipe.appspot.com",
  appId: "1:265523785724:web:88fda6ab79f8f72b6d8c79",
  measurementId: "G-57223QKFEC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Set references
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

export const fetchAllRingsFirebase = async (collectionName = "rings") => {
  if (false) {
    return devRingData;
  }
  console.log("starting execution", new Date().toLocaleTimeString());

  const listOfRings = await getDocs(collection(firestore, collectionName));

  /* try {
    for (const docSnapshot of listOfRings.docs) {
      console.log("updating ring with id", docSnapshot.id);

      await updateDoc(docSnapshot.ref, {
        shuffledSortOrder: Math.random(),
      });
    }
  } catch (error) {
    console.error("Error updating document: ", error);
  } */

  /*   for (let ring of listOfRings) {
    if (await isBroken(ring.url)) {
      await updateAttributeOfRingWithIdInCollection(true, "isDeleted", ring.id);
    } else
      await updateAttributeOfRingWithIdInCollection(
        false,
        "isDeleted",
        ring.id
      );
  } */

  console.log("finished execution of getting all the rings", new Date().toLocaleTimeString());

  return shuffle(listOfRings);
};

type FilteredRingResult = {
  rings: IRing[];
  lastDoc: QueryDocumentSnapshot | null;
  allRings: IRing[];
};

export const getLimitedNumberFromCollection = async (
  numberOfDocs = 50,
  viewedRingsArray: string[] = [],
  filterOnTrueKeys = {
    mainStoneSize: [],
    stoneCut: [],
  },
  lastRingFetched: QueryDocumentSnapshot | null = null,
  seed: number = 0,
): Promise<FilteredRingResult> => {
  const collectionRef = collection(firestore, "rings");
  let allRings: IRing[] = [];
  let documents: IRing[] = [];
  let lastDoc = lastRingFetched;
  let remainingDocs = numberOfDocs;
  let orderByField = "id";
  let sortOrder: OrderByDirection = "asc";

  if (seed < 0.33) {
    orderByField = "shuffledSortOrder";
    sortOrder = "desc";
  } else if (seed < 0.66) {
    orderByField = "shuffledSortOrder";
    sortOrder = "asc";
  } else {
    orderByField = "rank";
    sortOrder = "asc";
  }

  while (remainingDocs > 0) {
    const q: Query = query(
      collectionRef,
      orderBy(orderByField, sortOrder),
      ...(lastDoc ? [startAfter(lastDoc)] : []),
      limit(remainingDocs),
      where("waitingForApproval", "==", false),
      where("isDeleted", "==", false),
    );

    let querySnapshot;
    try {
      querySnapshot = await getDocs(q);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }

    if (!querySnapshot || querySnapshot.empty) {
      break;
    }
    for (const doc of querySnapshot.docs) {
      if (documents.length >= numberOfDocs) {
        // Break the loop if we have enough documents
        break;
      }
      const docToAdd: IRing = doc.data() as IRing;
      if (!viewedRingsArray.includes(doc.id)) {
        allRings.push({ ...docToAdd });
      }

      if (
        !viewedRingsArray.includes(doc.id) &&
        ObjectsInArrayFilter([docToAdd], filterOnTrueKeys).length > 0
      ) {
        documents.push({ ...docToAdd });
      }
    }
    lastDoc = querySnapshot.docs[querySnapshot.size - 1];

    remainingDocs = numberOfDocs - documents.length;
  }
  return { rings: shuffle(documents), lastDoc, allRings };
};

// only works when mainStoneSize is not altered
export const getLimitedNumberAndFilterCollection = async (
  numberOfDocs = 50,
  viewedRingsArray: string[] = [],
  filterOnTrueKeys = {
    mainStoneSize: [],
    stoneCut: [],
  },
  lastRingFetched: QueryDocumentSnapshot | null = null,
  seed: number = 0,
) => {
  const collectionRef = collection(firestore, "rings");
  let ringData: IRing[] = [];
  let lastDoc = lastRingFetched;
  let orderByField = "id";
  let sortOrder: OrderByDirection = "asc";

  console.log("seed", seed);

  if (seed < 0.33) {
    orderByField = "shuffledSortOrder";
    sortOrder = "desc";
  } else if (seed < 0.66) {
    orderByField = "shuffledSortOrder";
    sortOrder = "asc";
  } else {
    orderByField = "rank";
    sortOrder = "asc";
  }

  const stoneCutFilter = filterOnTrueKeys.stoneCut.filter((stoneCut) => stoneCut);

  const q: Query = query(
    collectionRef,
    orderBy(orderByField, sortOrder),
    ...(lastDoc ? [startAfter(lastDoc)] : []),
    limit(numberOfDocs),
    where("stoneCut", "array-contains-any", stoneCutFilter),
    where("waitingForApproval", "==", false),
    where("isDeleted", "==", false),
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log("No matching documents.");
    return;
  }

  querySnapshot.forEach((doc) => {
    const docToAdd: IRing = doc.data() as IRing;
    if (
      !viewedRingsArray.includes(doc.id) &&
      docToAdd.isDeleted === false &&
      stoneCutFilter.every((cut) => docToAdd.stoneCut.includes(cut)) // Ensure all values in stoneCutFilter are in stoneCut
    ) {
      ringData.push({ ...docToAdd });
    }
  });

  return { rings: shuffle(ringData), lastDoc: 0, allRings: ringData };
};

export const fetchRingsByIds = async (ringIds: string[]): Promise<IRing[]> => {
  const collectionRef = collection(firestore, "rings");
  const batchSize = 10;
  const promises: Promise<IRing[]>[] = [];

  for (let i = 0; i < ringIds.length; i += batchSize) {
    const batch = ringIds.slice(i, i + batchSize);
    const q = query(collectionRef, where("id", "in", batch));
    promises.push(
      getDocs(q).then((querySnapshot) => {
        const rings: IRing[] = [];
        querySnapshot.forEach((doc) => {
          const ringData: IRing = doc.data() as IRing;
          rings.push(ringData);
        });
        return rings;
      }),
    );
  }

  // Use Promise.all to wait for all promises to resolve, and then flatten the array of arrays.
  const results = await Promise.all(promises);
  return results.flat();
};

export const getFilteredListOfCollection = async (
  collectionName = "rings",
  filters = { mainStoneSize: [5], stoneCut: ["oval", "round"] },
) => {
  const collectionRef = collection(firestore, collectionName);

  let filteredQuery = query(collectionRef);

  Object.entries(filters).forEach(([field, values]) => {
    values.forEach((value) => {
      filteredQuery = query(filteredQuery, where(field, "==", value));
    });
  });

  const querySnapshot = await getDocs(filteredQuery);

  const listOfCollection = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return listOfCollection;
};

export const getRingsWaitingForApproval = async () => {
  const collectionRef = collection(firestore, "rings");
  const q = query(
    collectionRef,
    where("waitingForApproval", "==", true),
    where("isDeleted", "==", false),
  );
  const querySnapshot = await getDocs(q);

  let filteredRings: IRing[] = [];

  querySnapshot.docs.forEach(async (docSnapshot) => {
    const ringData = docSnapshot.data() as DocumentData;
    const ringId = docSnapshot.id;
    const updatedRingData = {
      id: ringId,
      ...ringData,
    };
    // @ts-ignore
    filteredRings.push(updatedRingData);

    await updateAttributeOfRingWithIdInCollection(ringId, "id", ringId);
  });

  return filteredRings;
};

export const getListOfCollection = async (collectionName = "users") => {
  const listSnapshot = await getDocs(collection(firestore, collectionName));

  const listOfDocuments = listSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return listOfDocuments as ICurrentUser[];
};

export const createSavedImagesMail = async (client: IClient) => {
  try {
    const docRef = await addDoc(collection(firestore, "mail"), {
      templateId: 8,
      to: client.email,
      timeStamp: Date.now(),
      params: {
        ...client,
      },
    });
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

export const sendMailTemplateToWithParamsAndSubject = (
  templateId: number,
  toEmail: string,
  params: Object,
  subject?: string,
) => {
  let bodyObject = {
    templateId,
    to: toEmail,
    params,
  };

  Object.assign(bodyObject, subject ? { subject } : {});

  fetch("https://us-central1-ring-swipe.cloudfunctions.net/sendMailHttpV2", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyObject),
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error("Failed to trigger function");
      }
    })
    .then((data) => {
      console.log(data); // This will be "Email processing started"
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const addLikeToRing = async (
  ringId: string,
  currentUser: ICurrentUser | null,
  country?: string | null,
  ipAddress?: string | null,
): Promise<void> => {
  const ringRef = doc(firestore, "rings", ringId);
  const likesRef = collection(ringRef, RING_SUBCOLLECTIONS.LIKES);
  const refToAdd = currentUser ? currentUser.id : NOT_LOGGED_IN_LIKE;

  // Define the type for ILikeDislike, adjust based on your actual type definition
  let newLiked: ILikeDislike;

  if (currentUser && currentUser.country) {
    newLiked = { id: refToAdd, date: Date.now(), country: currentUser.country };
  } else {
    newLiked = { id: refToAdd, date: Date.now(), country: country || null };
  }

  const ringSnapshot = await getDoc(ringRef);
  const ringData = ringSnapshot.data();

  if (ringData?.uploadedBy !== NOT_LOGGED_IN) {
    emailIfLikesAreEnough(ringData as IRing);
  }

  try {
    // Create document ID based on the logged-in status and IP address
    const docId =
      refToAdd === NOT_LOGGED_IN_LIKE
        ? ipAddress
          ? `${NOT_LOGGED_IN_LIKE}_${ipAddress}`
          : `${NOT_LOGGED_IN_LIKE}_unknown_ip_${newLiked.date}`
        : refToAdd;

    const docRef = doc(likesRef, docId);
    await setDoc(docRef, newLiked);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

const emailIfLikesAreEnough = (ringData: IRing) => {
  const { id, uploadedBy, numberOfLikes } = ringData;

  if (!uploadedBy) {
    return;
  }

  const uploadedById = typeof uploadedBy === "object" ? uploadedBy.id : uploadedBy;

  const sendEmailWithSubject = async (subject: string) => {
    const user = await getUserWithId(uploadedById);

    if (user !== null)
      try {
        await sendMailTemplateToWithParamsAndSubject(
          10,
          user.email,
          { url: RINGS_URL + id },
          subject,
        );
      } catch (error) {
        console.error(error);
      }
  };

  switch (numberOfLikes + 1) {
    case 1:
      sendEmailWithSubject("Your ring has received its first left swipe!");
      break;
    case 10:
      sendEmailWithSubject("Your ring has received 10 likes!");
      break;
    case 25:
      sendEmailWithSubject("Your ring has received 25 likes!");
      break;
    case 50:
      sendEmailWithSubject("Your ring has received 50 likes!");
      break;
    case 100:
      sendEmailWithSubject("Your ring has broken the barrier of 100 likes!");
      break;
    case 250:
      sendEmailWithSubject("Your ring has more than 250 likes!");
      break;
    case 1000:
      sendEmailWithSubject("Your ring has the insane amount of Likes of 1000!");
      break;
    default:
      break;
  }
};

export const addDislikeToRing = async (
  ringId: string,
  currentUser: ICurrentUser | null,
  country?: string | null,
  ipAddress?: string | null,
): Promise<void> => {
  const ringRef = doc(firestore, "rings", ringId);
  const dislikesRef = collection(ringRef, RING_SUBCOLLECTIONS.DISLIKES);
  const refToAdd = currentUser ? currentUser.id : NOT_LOGGED_IN_LIKE;

  // Define the type for ILikeDislike, adjust based on your actual type definition
  let newDisliked: ILikeDislike;

  if (currentUser && currentUser.country) {
    newDisliked = { id: refToAdd, date: Date.now(), country: currentUser.country };
  } else {
    newDisliked = { id: refToAdd, date: Date.now(), country: country || null };
  }

  try {
    // Create document ID
    const docId =
      refToAdd === NOT_LOGGED_IN_LIKE
        ? ipAddress
          ? `${NOT_LOGGED_IN_LIKE}_${ipAddress}`
          : `${NOT_LOGGED_IN_LIKE}_unknown_ip_${newDisliked.date}`
        : refToAdd;

    const docRef = doc(dislikesRef, docId);
    await setDoc(docRef, newDisliked);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

export const updateAttributeOfRingWithIdInCollection = async (
  newValue: string | number | boolean | string[] | Date,
  attribute: string,
  ringId: string,
  collectionName = "rings",
) => {
  const ringRef = doc(firestore, collectionName, ringId);

  console.log("updating ring with id", ringId, "with", attribute, ":", newValue);

  try {
    await updateDoc(ringRef, {
      [attribute]: newValue,
    });
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};

export const addRingToViewedForUser = async (ringId: string, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  const userSnapshot = await getDoc(userRef);
  const userData = userSnapshot.data();

  let newViewedRings = [ringId];

  if (userData?.viewedRings) {
    newViewedRings = [...userData.viewedRings, ringId];
  }

  try {
    await updateDoc(userRef, {
      viewedRings: newViewedRings,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const setViewedRingsForUser = async (viewedRings: Array<string>, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  try {
    await updateDoc(userRef, {
      viewedRings: viewedRings,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const setLikedRingsForUser = async (
  likedRings: Array<string> | Array<Object>,
  userId: string,
) => {
  const userRef = doc(firestore, "users", userId);

  try {
    await updateDoc(userRef, {
      likedRings: likedRings,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const addRingToLikedForUser = async (ringId: string, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  try {
    const likedRing = { id: ringId, date: Date.now() };

    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.data();

    if (userData && userData.likedRings) {
      // If the field exists, append the likedRing object
      await updateDoc(userRef, {
        likedRings: arrayUnion(likedRing),
      });
    } else {
      // If the field doesn't exist, create it with the initial array
      await setDoc(
        userRef,
        {
          likedRings: [likedRing],
        },
        { merge: true },
      );
    }
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const addRingToDislikedForUser = async (ringId: string, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  try {
    const dislikedRing = { id: ringId, date: Date.now() };

    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.data();

    if (userData && userData.likedRings) {
      // If the field exists, append the likedRing object
      await updateDoc(userRef, {
        dislikedRings: arrayUnion(dislikedRing),
      });
    } else {
      // If the field doesn't exist, create it with the initial array
      await setDoc(
        userRef,
        {
          dislikedRings: [dislikedRing],
        },
        { merge: true },
      );
    }
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const unlikeRingForUser = async (ringId: string, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  try {
    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.data();

    const newLikedRings = userData?.likedRings.filter((likedRing: string | ILikeDislike) =>
      typeof likedRing === "object" ? likedRing.id !== ringId : likedRing !== ringId,
    );

    return updateDoc(userRef, {
      likedRings: newLikedRings,
    });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

export const removeLikeFromRing = async (ringId: string, currentUserId: string) => {
  const ringDocRef = doc(firestore, "rings", ringId);
  const likesCollectionRef = collection(ringDocRef, "likes");

  try {
    const q = query(likesCollectionRef, where("id", "==", currentUserId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log(`No like found for user ${currentUserId} on ring ${ringId}`);
      return;
    }

    for (const docSnapshot of querySnapshot.docs) {
      try {
        await deleteDoc(docSnapshot.ref);
        console.log(`Document with ID ${docSnapshot.id} successfully deleted!`);
      } catch (deleteError) {
        console.error(`Error deleting document with ID ${docSnapshot.id}:`, deleteError);
      }
    }
  } catch (error) {
    console.error("Error querying documents:", error);
  }
};

export const deleteRingFromCollectionWithId = async (collectionName: string, ringId: string) => {
  let errors = [];

  try {
    const ringRef = doc(firestore, collectionName, ringId);
    await deleteDoc(ringRef);
    console.log(ringId + " successfully deleted!");
  } catch (error) {
    console.error("Error removing document: ", error);
    errors.push(error);
  }

  return errors;
};

export const deleteRingFromStorageWithUrl = async (ringUrl: string | Array<string>) => {
  let errors = [];

  try {
    if (Array.isArray(ringUrl)) {
      for (const url of ringUrl) {
        const storageRef = ref(storage, url);
        await deleteObject(storageRef);
        console.log(`Storage file ${url} successfully deleted!`);
      }
    } else {
      const storageRef = ref(storage, ringUrl);
      await deleteObject(storageRef);
      console.log("Storage file successfully deleted!");
    }
  } catch (error) {
    console.error("Error removing document: ", error);
    errors.push(error);
  }

  return errors;
};

export const updatePropForUserWithId = async (prop: string, value: any, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  const updateData: Record<string, any> = {};
  updateData[prop] = value;

  try {
    await updateDoc(userRef, updateData);
  } catch (error) {
    console.error("error updating document", error);
    throw error;
  }
};

export const updateLastSignInForUserId = async (userId: string) => {
  const userRef = doc(firestore, "users", userId);

  const userSnapshot = await getDoc(userRef);

  const userData = userSnapshot.data();

  if (!userData) return;

  const ONE_MINUTE = 60000;
  if (userData.latestSignIn && Date.now() - userData.latestSignIn < ONE_MINUTE) {
    return;
  }

  const updateObject = { latestSignIn: Date.now() };

  if (userData.hasOwnProperty("latestSignIn")) {
    Object.assign(updateObject, { previousSignIn: userData.latestSignIn });
  }

  try {
    await updateDoc(userRef, updateObject);
  } catch (error) {
    console.error("error updating document", error);
    throw error;
  }
};

export const getUserWithId = async (id: string) => {
  const userRef = doc(firestore, `users/${id}`);

  const userSnapShot = await getDoc(userRef);

  if (!userSnapShot.exists()) {
    throw new Error("user with id does not exist");
  }

  const userData = userSnapShot.data();

  return { id, ...userData } as ICurrentUser;
};

export const getRingWithId = async (id: string) => {
  const ringRef = doc(firestore, `rings/${id}`);

  const ringSnapShot = await getDoc(ringRef);

  if (!ringSnapShot.exists()) {
    console.error("ring with id does not exist");
    return null;
  }

  const ringData = ringSnapShot.data();

  return { ...ringData };
};

export const addUploadedRingWithIdToUser = async (ringId: string, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  const userSnapshot = await getDoc(userRef);

  const userData = userSnapshot.data();

  let newUploadedRings = [ringId];

  if (userData?.uploadedRings) newUploadedRings = [...userData.uploadedRings, ringId];

  const updatedData = {
    uploadedRings: newUploadedRings,
  };

  try {
    await updateDoc(userRef, updatedData);
    return null;
  } catch (error) {
    console.error("error updating document", error);
    return error;
  }
};

export const deleteUploadedRingFromUserWithId = async (ringId: string, userId: string) => {
  const userRef = doc(firestore, "users", userId);

  try {
    await updateDoc(userRef, { uploadedRings: arrayRemove(ringId) });
    return null;
  } catch (error) {
    console.error("error updating document", error);
    return error;
  }
};

export const updateDisplayNameForRingIds = (newDisplayName: string, ringIds: Array<string>) => {
  ringIds.forEach((id) => {
    const docRef = doc(firestore, "rings", id);
    updateDoc(docRef, {
      "uploadedBy.name": newDisplayName,
    }).catch((error) => {
      console.error("Error updating document: ", error);
    });
  });
};

// COMMENTS

export const getForRingIdCommentsSubcollection = async (
  ringId: string,
  subcollectionName: string = "comments",
): Promise<Array<IComment>> => {
  const ringRef = doc(firestore, "rings", ringId);
  const subcollectionRef = collection(ringRef, subcollectionName);

  try {
    const subcollectionSnapshot = await getDocs(subcollectionRef);
    const subcollectionDocs: Array<IComment> = subcollectionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as IComment),
    }));
    return subcollectionDocs;
  } catch (error) {
    console.error("Error fetching subcollection: ", error);
    return [];
  }
};

export const getForListIdCommentsSubcollection = async (
  listId: string,
  subcollectionName: string = "comments",
): Promise<Array<IComment>> => {
  const listRef = doc(firestore, "lists", listId);
  const subcollectionRef = collection(listRef, subcollectionName);

  try {
    const subcollectionSnapshot = await getDocs(subcollectionRef);
    const subcollectionDocs: Array<IComment> = subcollectionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as IComment),
    }));
    return subcollectionDocs;
  } catch (error) {
    console.error("Error fetching subcollection: ", error);
    return [];
  }
};

// IMPORT MANY RINGS

export const uploadListOfRings = async (listOfRings: Array<IRing>) => {
  const newRingsCollectionRef = collection(firestore, "newRings");

  for (const ring of listOfRings) {
    const ringDocRef = doc(newRingsCollectionRef);
    try {
      await setDoc(ringDocRef, ring);
      console.log("Document written successfully");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }
};

export const addCommentToRingWithId = async (comment: IComment, ringId: string) => {
  const ringRef = doc(firestore, "rings", ringId);
  const commentsRef = collection(ringRef, "comments");

  try {
    await addDoc(commentsRef, comment);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

export const addCommentToListWithId = async (comment: IComment, listId: string) => {
  const listRef = doc(firestore, "lists", listId);
  const commentsRef = collection(listRef, "comments");

  try {
    await addDoc(commentsRef, comment);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

// IMAGE UPLOAD
export const addRingToFirebase = (ring: IRing) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = await addDoc(collection(firestore, "rings"), ring);
      await updateDoc(docRef, { id: docRef.id });
      resolve(docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
      reject(error);
    }
  });
};

//MIGRATION

export const migrateLikedDataToSubcollections = async () => {
  console.log("Data migration started");

  const ringsCollection = collection(firestore, "rings");
  const snapshot = await getDocs(ringsCollection);

  for (const docSnapshot of snapshot.docs) {
    const data = docSnapshot.data();
    const ringId = docSnapshot.id;
    console.log(`Processing document ${ringId}`);

    // Check if the document has already been processed
    if (data.migrationCompleted) {
      console.log(`Document ${ringId} already processed. Skipping.`);
      continue;
    }

    await processDocument(ringId, data);
  }

  console.log("Data migration completed successfully");
};

async function processDocument(ringId: string, data: DocumentData) {
  const ringRef = doc(firestore, "rings", ringId);

  try {
    // Process 'liked' array
    if (data.liked && Array.isArray(data.liked)) {
      await processArray(ringRef, "likes", data.liked);
      console.log(`Added liked items for document ${ringId}`);
    } else {
      console.log(`No liked array found for document ${ringId}`);
    }

    // Process 'disliked' array
    if (data.disliked && Array.isArray(data.disliked)) {
      await processArray(ringRef, "dislikes", data.disliked);
      console.log(`Added disliked items for document ${ringId}`);
    } else {
      console.log(`No disliked array found for document ${ringId}`);
    }

    // Remove the old liked and disliked arrays from the document
    await updateDoc(ringRef, {
      liked: null,
      disliked: null,
      migrationCompleted: true, // Mark the document as processed
    });

    console.log(`Processed document ${ringId}`);
  } catch (error) {
    if (error instanceof Error) {
      console.error(`Operation failed for document ${ringId}`, {
        error: error.message,
        stack: error.stack,
      });
    } else {
      console.error(`Operation failed for document ${ringId}`, {
        error: String(error),
      });
    }
  }
}

async function processArray(ringRef: any, subcollectionName: string, items: any[]) {
  for (const item of items) {
    const data = typeof item === "object" && item !== null ? item : { id: item, date: Date.now() };
    await addDoc(collection(ringRef, subcollectionName), data);
  }
}

export const cleanupSubcollections = async () => {
  console.log("Cleanup process started");

  const ringsCollection = collection(firestore, "rings");
  const snapshot = await getDocs(ringsCollection);

  for (const docSnapshot of snapshot.docs) {
    const ringId = docSnapshot.id;
    console.log(`Processing document ${ringId}`);

    const ringRef = doc(firestore, "rings", ringId);

    // Step 3: Validate and update numberOfLikes and numberOfDislikes
    await validateAndUpdateCounts(ringRef, "likes", "numberOfLikes");
    await validateAndUpdateCounts(ringRef, "dislikes", "numberOfDislikes");

    // Step 4: Remove leftovers of liked or disliked arrays and delete migrationCompleted flag
    await updateDoc(ringRef, {
      cleanupCompleted: deleteField(),
    });

    console.log(`Processed document ${ringId}`);
  }

  console.log("Cleanup process completed successfully");
};

async function deleteSubcollection(ringRef: any, subcollectionName: string) {
  console.log(`Starting deletion of subcollection ${subcollectionName} for document ${ringRef.id}`);

  const subcollectionRef = collection(ringRef, subcollectionName);
  const snapshot = await getDocs(subcollectionRef);

  if (snapshot.empty) {
    console.log(`Subcollection ${subcollectionName} is already empty for document ${ringRef.id}`);
    return;
  }

  // Initialize the batch
  let batch = writeBatch(firestore);
  let count = 0;

  // Add each delete operation to the batch
  snapshot.docs.forEach((docSnapshot) => {
    batch.delete(docSnapshot.ref);
    count++;
    console.log(
      `Added delete operation for document ${docSnapshot.id} in subcollection ${subcollectionName}`,
    );

    // Commit the batch if it reaches the Firestore limit of 500 operations
    if (count === 500) {
      console.log(`Committing batch for ${count} operations...`);
      batch
        .commit()
        .then(() => {
          console.log(`Batch committed successfully for ${count} operations.`);
        })
        .catch((error) => {
          console.error(`Error committing batch: ${error}`);
        });
      // Reset batch and counter for the next set of operations
      batch = writeBatch(ringRef.firestore);
      count = 0;
    }
  });

  // Commit the remaining operations in the batch
  if (count > 0) {
    console.log(`Committing final batch for ${count} operations...`);
    await batch
      .commit()
      .then(() => {
        console.log(`Final batch committed successfully for ${count} operations.`);
      })
      .catch((error) => {
        console.error(`Error committing final batch: ${error}`);
      });
  }

  console.log(
    `Deleted subcollection ${subcollectionName} for document ${ringRef.id} successfully.`,
  );
}

async function cleanupSubcollection(ringRef: any, subcollectionName: string) {
  const subcollectionRef = collection(ringRef, subcollectionName);
  const snapshot = await getDocs(subcollectionRef);
  const uniqueRecords: { [key: string]: any } = {};

  for (const docSnapshot of snapshot.docs) {
    const data = docSnapshot.data();
    const key = `${data.id}_${data.date}`;

    // If the record with the same id and date exists, skip it
    if (!uniqueRecords[key]) {
      uniqueRecords[key] = data;
    }
  }

  // Clear the subcollection
  await deleteSubcollection(ringRef, subcollectionName);

  // Re-add the unique records
  for (const key in uniqueRecords) {
    const uniqueDoc = uniqueRecords[key];
    let docRef;
    if (uniqueDoc.id === NOT_LOGGED_IN_LIKE)
      docRef = doc(subcollectionRef, uniqueDoc.id.replace(/ /g, "_") + "_" + uniqueDoc.date); // Modified to include date in the doc ID
    else docRef = doc(subcollectionRef, uniqueDoc.id);
    await setDoc(docRef, uniqueDoc);
  }

  console.log(`Cleaned up subcollection ${subcollectionName} for document ${ringRef.id}`);
}

async function validateAndUpdateCounts(ringRef: any, subcollectionName: string, fieldName: string) {
  const subcollectionRef = collection(ringRef, subcollectionName);
  const snapshot = await getCountFromServer(subcollectionRef);
  const actualCount = snapshot.data().count;

  const ringDoc = await getDoc(ringRef);
  const data = ringDoc.data() as DocumentData;
  const expectedCount = data[fieldName];

  if (actualCount !== expectedCount) {
    await updateDoc(ringRef, {
      [fieldName]: actualCount,
    });
    console.log(`Updated ${fieldName} for document ${ringRef.id} to ${actualCount}`);
  } else
    console.log(
      `Did not update ${fieldName} for document ${ringRef.id}, actual count: ${actualCount} is equal to expected count: ${expectedCount}`,
    );
}

export const getListWithId = async (id: string): Promise<IList | null> => {
  const listRef = doc(firestore, `lists/${id}`);

  const listSnapShot = await getDoc(listRef);

  if (!listSnapShot.exists()) {
    console.error("list with id does not exist");
    return null;
  }

  const listData = listSnapShot.data();

  return listData as IList;
};

export const getRingIdsForList = async (listId: string) => {
  const ringsRef = collection(firestore, `lists/${listId}/rings`);
  const ringsSnapshot = await getDocs(ringsRef);

  const ringsData = ringsSnapshot.docs.map((doc) => doc.id);

  return ringsData;
};

export const getNumberOfRingIdsForList = async (listId: string, amount: number) => {
  const ringsRef = collection(firestore, `lists/${listId}/rings`);

  const limitedQuery = query(ringsRef, limit(amount));
  const ringsSnapshot = await getDocs(limitedQuery);

  const ringsData = ringsSnapshot.docs.map((doc) => doc.id);

  return ringsData;
};

export const saveRingWithIdToListId = async (ringId: string, listId: string) => {
  try {
    const ringDocRef = doc(firestore, `lists/${listId}/rings`, ringId);

    const ringData = {
      id: ringId,
      addedAt: new Date(),
    };

    await setDoc(ringDocRef, ringData);

    console.log(`Ring with ID ${ringId} was successfully saved to list ${listId}`);
  } catch (error) {
    console.error("Error saving ring to list:", error);
    throw new Error("Failed to save ring to list.");
  }
};

export const getDisplayNameFromId = async (userId: string) => {
  const userRef = doc(firestore, `users/${userId}`);

  const userSnapShot = await getDoc(userRef);

  if (!userSnapShot.exists()) {
    console.error("user with id does not exist");
    return null;
  }

  const userData = userSnapShot.data();

  return userData.displayName;
};

export const createListWithName = async (name: string, createdBy: string) => {
  try {
    const listRef = doc(collection(firestore, "lists"));

    const newList = {
      id: listRef.id,
      name,
      createdBy,
      createdOn: new Date(),
    };

    await setDoc(listRef, newList);

    const userRef = doc(firestore, "users", createdBy);
    await updateDoc(userRef, {
      lists: arrayUnion(listRef.id),
    });

    return listRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  }
};

export const addViewToListWithId = async (viewedBy: string, country: string, listId: string) => {
  try {
    console.log(listId);

    const listRef = doc(firestore, "lists", listId);

    const viewsRef = collection(listRef, "views");

    const viewDocRef = doc(viewsRef, viewedBy);

    const newView = {
      date: new Date(),
      viewedBy,
      country,
    };

    await setDoc(viewDocRef, newView);

    console.log("View added successfully with ID:", viewedBy);
  } catch (error) {
    console.error("Error adding view: ", error);
  }
};
