import { useEffect, FC, useState } from "react";
import "./collection-viewer.scss";
import RingSwipe from "../../components/ring-swipe/ring-swipe";
import { useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  addViewToListWithId,
  fetchRingsByIds,
  getDisplayNameFromId,
  getListWithId,
  getRingIdsForList,
} from "../../utils/firebase.utils";
import { IList, IRing } from "../../utils/interfaces";
import Spinner from "../../components/spinner/spinner";
import BasePage from "../../components/base-page/base-page";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { fetchIpAndCountry } from "../../utils/firebase.auth.utils";
import { useTranslation } from "react-i18next";
import { selectModalHidden } from "../../redux/modal/modal.selectors";
import OverlayModal from "../../components/overlay-modal/overlay-modal";
import SaveImages from "../../components/modals/save-images";
import ThankYou from "../../components/modals/thank-you";
import { toggleModalHidden } from "../../redux/modal/modal.actions";
import { Dispatch } from "redux";
import { selectLikedRingUrls } from "../../redux/rings/rings.selectors";

interface ICollectionProps {
  toggleModalHidden: () => void;
}

const CollectionViewer: FC<ICollectionProps> = ({ toggleModalHidden }) => {
  const location = useLocation();
  const [collectionId, setCollectionId] = useState<string | undefined>(undefined);
  const [collection, setCollection] = useState<IList | null>(null);
  const [ringIdsFromList, setRingIdsFromList] = useState<string[]>([]);
  const [rings, setRings] = useState<IRing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayName, setDisplayName] = useState<string | null>(null);
  const [isSent, setIsSent] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const modalHidden = useSelector(selectModalHidden);
  const likedRingUrls = useSelector(selectLikedRingUrls);

  const { t } = useTranslation();

  useEffect(() => {
    const extractCollectionId = () => {
      let listId = location.state?.collectionId;
      if (!listId && location.pathname) {
        const pathParts = location.pathname.split("/");
        if (pathParts.length > 2) {
          listId = pathParts[2]; // Assuming the ID is always at this position
        }
      }
      setCollectionId(listId);
    };
    extractCollectionId();
  }, [location]);

  useEffect(() => {
    const fetchList = async () => {
      if (!collectionId) return;

      try {
        const ringIds = await getRingIdsForList(collectionId);

        if (ringIds) {
          setRingIdsFromList(ringIds);
        }

        const collectionData = await getListWithId(collectionId);

        if (collectionData) {
          setCollection(collectionData as IList);
        }
      } catch (error) {
        console.error("Failed to set list from URL: ", error);
      }
    };
    fetchList();
  }, [collectionId]);

  useEffect(() => {
    if (ringIdsFromList.length > 0) {
      fetchRingsByIds(ringIdsFromList).then((result) => {
        console.log("Rings from fetch: ", result);
        setRings(result);
        setIsLoading(false);
      });
    }
  }, [ringIdsFromList]);

  useEffect(() => {
    const fetchDisplayName = async () => {
      if (collection?.createdBy) {
        const name = await getDisplayNameFromId(collection.createdBy);
        setDisplayName(name);
      }
    };

    fetchDisplayName();
  }, [collection?.createdBy]);

  useEffect(() => {
    if (!collectionId) return;

    const addViewToCollection = async () => {
      try {
        const { ipAddress = "unknown_ip", country = "unknown_country" } = await fetchIpAndCountry();

        const viewedBy = currentUser ? currentUser.id : `not_logged_in_${ipAddress}`;

        const viewedFrom = country ? country : "unknown_country";

        await addViewToListWithId(viewedBy, viewedFrom, collectionId);
      } catch (error) {
        console.error("Error adding view to collection:", error);
      }
    };
    addViewToCollection();
  }, [currentUser, collectionId]);

  return (
    <BasePage showLikedRingUrls={true}>
      {!modalHidden && (
        <OverlayModal close={toggleModalHidden}>
          {!isSent ? (
            <SaveImages likedRingUrls={likedRingUrls} imagesSaved={() => setIsSent(true)} />
          ) : (
            <ThankYou closeModal={toggleModalHidden} />
          )}
        </OverlayModal>
      )}
      <div className="collection__tag">
        {t("collectionViewer.Collection {{collectionName}} curated by {{displayName}}", {
          collectionName: collection?.name,
          displayName: displayName,
        })}
      </div>
      <div className="collection__container">
        <div className="collection__card-container">
          {isLoading ? (
            <Spinner />
          ) : (
            <RingSwipe
              NoMoreRingsToFetch={false}
              ringsFromDb={rings}
              setAllFiltersToTrue={function (): void {}}
              fetchRings={function (): void {}}
              collectionId={collectionId}
            />
          )}
        </div>
      </div>
    </BasePage>
  );
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleModalHidden: () => dispatch(toggleModalHidden()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionViewer);
