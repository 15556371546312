import { useState, useEffect } from "react";
import "./admin.scss";

// components
import Spinner from "../../components/spinner/spinner.tsx";
import Select from "react-select";

// utils
import {
  deleteUploadedRingFromUserWithId,
  deleteRingFromCollectionWithId,
  deleteRingFromStorageWithUrl,
  updateAttributeOfRingWithIdInCollection,
  getUserWithId,
  sendMailTemplateToWithParamsAndSubject,
  getRingsWaitingForApproval,
} from "../../utils/firebase.utils";
import {
  NOT_LOGGED_IN,
  RINGS_URL,
  getObjectsFromDataMatchingArrayOrString,
  metalTagsData,
  stoneCutTagsData,
  stoneTypeTagsData,
  colorTagsData,
  styleTagsData,
  settingTagsData,
} from "../../utils/constants.utils";

// svgs
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as LinkIcon } from "../../assets/add_link.svg";

const AdminPage = () => {
  const [rings, setRings] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [noRingsWaiting, setNoRingsWaiting] = useState(false);

  useEffect(() => {
    getRingsWaitingForApproval().then((result) => {
      setRings(result);
      if (result.length === 0) setNoRingsWaiting(true);
    });
  }, []);

  const toggleSelected = (ring) => {
    const { id } = ring;
    if (selectedList.includes(id))
      return setSelectedList(selectedList.filter((ringIdInList) => ringIdInList !== id));

    setSelectedList([...selectedList, id]);
  };

  const approveSelectedRings = async () => {
    for (const id of selectedList) {
      await updateAttributeOfRingWithIdInCollection(false, "waitingForApproval", id, "rings");
      await updateAttributeOfRingWithIdInCollection(Date.now(), "addedOn", id, "rings");
      const approvedRing = rings.find((ring) => ring.id === id);
      const { uploadedBy } = approvedRing;
      if (uploadedBy !== NOT_LOGGED_IN) {
        try {
          const user = await getUserWithId(uploadedBy.id);
          await sendMailTemplateToWithParamsAndSubject(
            9,
            user.email,
            { url: RINGS_URL + id },
            "Your ring has been approved",
          );
        } catch (error) {
          // Handle or log the error
          console.error("Error sending email:", error);
        }
      }
    }

    const updatedRingList = rings.filter(
      (ringFromRings) => !selectedList.includes(ringFromRings.id),
    );

    setRings(updatedRingList);
    setSelectedList([]);
  };

  const deleteSelectedRings = async () => {
    if (window.confirm("Are you sure you want to delete these rings?")) {
        const ringIdsToDelete = [...selectedList];
        const deletionPromises = ringIdsToDelete.map(async (id) => {
            const ringToDelete = rings.find((ring) => ring.id === id);
            return deleteRingMulti(null, ringToDelete);
        });

        await Promise.all(deletionPromises);
        
        // Filter out the deleted rings from the local rings array
        const updatedRings = rings.filter(ring => !ringIdsToDelete.includes(ring.id));
        setRings(updatedRings);

        alert("Rings successfully deleted from Firestore and from storage");
    }
};


  const deleteRing = (event, ring) => {
    if (event != null) event.stopPropagation();
    const { url, id, uploadedBy } = ring;
    if (window.confirm("Are you sure you want to delete this ring?")) {
      setSelectedList([]);
      deleteRingFromCollectionWithId("rings", id).then((errors) => {
        if (errors.length) return;

        alert("ring successfully deleted from firestore");
      });
      deleteRingFromStorageWithUrl(url).then((errors) => {
        if (errors.length) return;

        alert("ring successfully deleted from storage");
        const updatedRingList = rings.filter((ringFromRings) => id !== ringFromRings.id);
        setRings(updatedRingList);
        if (uploadedBy === NOT_LOGGED_IN) return;

        if (typeof uploadedBy === "string") deleteUploadedRingFromUserWithId(id, uploadedBy);
        else deleteUploadedRingFromUserWithId(id, uploadedBy.id);
      });
    }
  };

  const deleteRingMulti = async (event, ring) => {
    if (event != null) event.stopPropagation();
    const { url, id, uploadedBy } = ring;
    setSelectedList([]);

    try {
        const errorsFromCollection = await deleteRingFromCollectionWithId("rings", id);
        if (errorsFromCollection.length) return;

        const errorsFromStorage = await deleteRingFromStorageWithUrl(url);
        if (errorsFromStorage.length) return;

        if (uploadedBy === NOT_LOGGED_IN) return;

        if (typeof uploadedBy === "string") {
            await deleteUploadedRingFromUserWithId(id, uploadedBy);
        } else {
            await deleteUploadedRingFromUserWithId(id, uploadedBy.id);
        }
    } catch (error) {
        console.error("Error deleting ring:", error);
    }
};
  return (
    <div className="admin-page">
      <div className="admin-page__container">
        {noRingsWaiting ? (
          <div>No rings waiting for approval</div>
        ) : rings.length === 0 ? (
          <Spinner theme="light" />
        ) : (
          <div className="admin-page__rings-container">
            <span className="admin-page__rings-container__count">Showing {rings.length} rings</span>
            {selectedList.map((selectedRing) => <span className="admin-page__rings-container__count">{selectedRing}</span>)}
            <div className="admin-page__image-container">
             
              {rings.map((ring) => (
                <div
                  key={ring.id}
                  className={`${selectedList.includes(ring.id) ? "selected " : ""}box`}
                  style={{
                    backgroundImage: `url(${Array.isArray(ring.url) ? ring.url[0] : ring.url})`,
                  }}
                  alt={ring.id}
                  onClick={() => toggleSelected(ring)}
                >
                  <select
                    defaultValue={parseInt(ring.mainStoneSize)}
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event) =>
                      updateAttributeOfRingWithIdInCollection(
                        event.target.value,
                        "stoneCut",
                        ring.id,
                        "rings",
                      )
                    }
                  >
                    <option value={5}>+0.1ct</option>
                    <option value={10}>+1ct</option>
                    <option value={15}>+2.5ct</option>
                    <option value={20}>+4.5ct</option>
                  </select>
                  <select
                    defaultValue={ring.type}
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event) =>
                      updateAttributeOfRingWithIdInCollection(
                        event.target.value,
                        "type",
                        ring.id,
                        "rings",
                      )
                    }
                  >
                    <option value={"ring"}>ring</option>
                    <option value={"stone"}>stone</option>
                  </select>
                  <Select
                    defaultValue={getObjectsFromDataMatchingArrayOrString([...stoneCutTagsData, ...stoneTypeTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData], ring.stoneCut)}
                    isMulti
                    onClick={(event) => event.stopPropagation()}
                    onChange={(array) =>
                      updateAttributeOfRingWithIdInCollection(
                        array.map((object) => object.value),
                        "stoneCut",
                        ring.id,
                        "rings",
                      )
                    }
                    options={[...stoneCutTagsData, ...stoneTypeTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData]}
                  />
                  <input
                    type="number"
                    defaultValue={ring.actualStoneSize}
                    onClick={(event) => event.stopPropagation()}
                    onBlur={(event) =>
                      updateAttributeOfRingWithIdInCollection(
                        parseFloat(event.target.value),
                        "actualStoneSize",
                        ring.id,
                        "rings",
                      )
                    }
                  />
                  <button
                    className="admin-page__delete-button"
                    onClick={(event) => deleteRing(event, ring)}
                  >
                    <DeleteIcon />
                  </button>
                  <br />
                  <span>${ring.salesPrice}</span>
                  <span>{ring.uploadedBy?.name}</span>
                  {ring.salesUrl && (
                    <a onClick={(event) => event.stopPropagation()} href={ring.salesUrl}>
                      <LinkIcon className="admin-page__link-icon" />
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedList.length && (
          <div>
            <button className="primary bottom-fixed" onClick={() => approveSelectedRings()}>
              Save {selectedList.length} selected rings
            </button>
            <button className="primary bottom-left-fixed" onClick={() => deleteSelectedRings()}>
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
