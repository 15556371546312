import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./collection-card.scss";
import { IList, IRing } from "../../utils/interfaces";
import { fetchRingsByIds, getNumberOfRingIdsForList } from "../../utils/firebase.utils";
import { useTranslation } from "react-i18next";
import Toast from "../toast/toast";

interface ICollectionCardProps {
  collection: IList;
}

const CollectionCard: FunctionComponent<ICollectionCardProps> = ({ collection }) => {
  const [rings, setRings] = useState<IRing[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const getListOfRings = async () => {
      try {
        const ringIds = await getNumberOfRingIdsForList(collection.id, 3);

        if (ringIds.length > 0) {
          const result = await fetchRingsByIds(ringIds);
          setRings(result);
        }
      } catch (error) {
        console.error("Error fetching rings: ", error);
      }
    };

    getListOfRings();
  }, [collection]);

  const handleCopyLinkToCollection = () => {
    copyLinkToCollection();

    if (buttonRef.current) {
      const button = buttonRef.current;
      const buttonRect = button.getBoundingClientRect();
      const containerRect = button.offsetParent?.getBoundingClientRect();

      if (containerRect) {
        setToastPosition({
          top: buttonRect.top - containerRect.top - button.offsetHeight - 10,
          left: buttonRect.left - containerRect.left,
        });
      }

      setShowToast(true);
    }
  };

  const copyLinkToCollection = () => {
    const link = `https://rinderr.com/collection/${collection.id}`;
    navigator.clipboard.writeText(link).catch((err) => {
      console.error("Could not copy text: ", err);
    });
  };

  return (
    <div className="collection-card__card-container card-container">
      {rings.length > 0 ? (
        rings.map(({ url, id }, index) => (
          <div
            key={id}
            style={
              {
                backgroundImage: `url(${Array.isArray(url) ? url[0] : url})`,
              } as React.CSSProperties
            }
            className="collection-card__card card"
          >
            {index === rings.length - 1 && (
              <>
                <span className="collection-card__title">{collection.name}</span>
                <button
                  ref={buttonRef}
                  onClick={handleCopyLinkToCollection}
                  className="collection-card__button"
                >
                  {t("general.Share")}
                </button>
                <Toast
                  message="Link to collection copied successfully"
                  show={showToast}
                  onClose={() => setShowToast(false)}
                  top={toastPosition.top}
                  left={toastPosition.left}
                />
              </>
            )}
          </div>
        ))
      ) : (
        <div className="collection-card__card card empty">
          <span className="collection-card__title">{collection.name}</span>
        </div>
      )}
    </div>
  );
};

export default CollectionCard;
