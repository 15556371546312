export const countTimeFromToday = (date: number) => {
  const today = new Date();
  const dateToCount = new Date(date);
  const diffTime = Math.abs(today.getTime() - dateToCount.getTime());

  if (diffTime < 1000 * 60 * 60) {
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    return `${diffMinutes} minutes ago`;
  }

  if (diffTime < 1000 * 60 * 60 * 24) {
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    return `${diffHours} hours ago`;
  }

  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return `${diffDays} days ago`;
};
