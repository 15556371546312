import React, { useEffect, useState } from "react";
import "./footer.scss";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// icons
import { ReactComponent as HomeIcon } from "../../assets/grid.svg";
import { ReactComponent as SavedIcon } from "../../assets/favorite.svg";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";

// utils
import { usePrevious } from "../../utils/hook.utils";

// selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectAllRings } from "../../redux/rings/rings.selectors";
import { ICurrentUser, IRing } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";

interface IFooter {
  currentUser: ICurrentUser;
  allRings: IRing[];
}

const Footer = ({ currentUser, allRings }: IFooter) => {
  const [ringLiked, setRingLiked] = useState(false);
  const [newRingsSinceLast, setNewRingsSinceLast] = useState<IRing[]>([]);
  const location = useLocation();
  let navigate = useNavigate();
  const previousCurrentUser = usePrevious(currentUser);
  const {t} = useTranslation();

  useEffect(() => {
    const filterRingsBasedOnDate = (listOfRings: IRing[]) => {
      setNewRingsSinceLast(
        listOfRings.filter((ring: IRing) => {
          if (ring.uploadedOn) {
            return currentUser.previousSignIn < ring.uploadedOn;
          }
          return false;
        }),
      );
    };

    if (allRings && allRings.length > 0) {
      filterRingsBasedOnDate(allRings);
    }
  }, [currentUser.previousSignIn, allRings]);

  useEffect(() => {
    if (!currentUser || !previousCurrentUser) return;
    if (currentUser.likedRings?.length === previousCurrentUser.likedRings?.length) return;

    setRingLiked(true);
    setNewRingsSinceLast([]);

    setTimeout(() => {
      setRingLiked(false);
    }, 500);
  }, [currentUser, previousCurrentUser]);

  return (
    <div className="footer">
      <div className="footer__option footer__left" onClick={() => navigate("/")}>
        <div className="footer__icon-container">
          <HomeIcon className={`${location.pathname === "/" && "active"} home-icon`} />
          {newRingsSinceLast.length > 0 && (
            <span className="footer__notification">{newRingsSinceLast.length}</span>
          )}
        </div>

        <label className={`${location.pathname === "/" && "active"}`}>{t('footer.Home')}</label>
      </div>
      <div className="footer__option" onClick={() => navigate("/upload-ring")}>
        <label className="footer__add-icon-button">
          <AddIcon className="add-icon" />
        </label>
      </div>
      <div className="footer__right">
        <div className="footer__option" onClick={() => navigate("/liked-rings")}>
          <SavedIcon
            className={`${location.pathname === "/liked-rings" && "active"} ${
              ringLiked && "ring-liked"
            } saved-icon`}
          />
          <label className={`${location.pathname === "/liked-rings" && "active"}`}>{t('footer.Saved')}</label>
        </div>
        <div className="footer__option" onClick={() => navigate("/profile/" + currentUser.id)}>
          <ProfileIcon
            className={`${location.pathname.includes("/profile/") && "active"} profile-icon`}
          />
          <label className={`${location.pathname.includes("/profile/") && "active"}`}>
          {t('footer.Profile')}
          </label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () =>
  createStructuredSelector({
    currentUser: selectCurrentUser,
    allRings: selectAllRings,
  });

export default connect(mapStateToProps, null)(Footer);
