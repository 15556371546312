import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./image-box.scss";

// components
import ImageButton from "../buttons/image-button";
import OverlayModal from "../overlay-modal/overlay-modal";
import RingDetailedView from "../ring-detailed-view/ring-detailed-view";

// svgs
import { ReactComponent as LikeIcon } from "../../assets/favorite.svg";
import { ReactComponent as WaitIcon } from "../../assets/wait.svg";
import { ReactComponent as SavedIcon } from "../../assets/favorite.svg";
import { ReactComponent as TagIcon } from "../../assets/sales_tag.svg";

// interfaces
import { IRing } from "../../utils/interfaces";
import { analytics, getRingWithId } from "../../utils/firebase.utils";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import { getUrlForAffiliate } from "../../utils/affiliate.utils";

export interface ImageBoxProps {
  id: string;
  url: string;
  salesUrl?: string;
  currency?: string;
  salesPrice?: number;
  uploadedBy?: string | { id: string; name: string };
  numberOfLikes: number;
  waitingForApproval?: boolean;
  showLikes: boolean;
  showLikeButton: boolean;
  showShopButton?: boolean;
  removeLike?: (event: SyntheticEvent, ringId: string) => void;
}

const ImageBox = ({
  id,
  url,
  salesUrl,
  currency,
  salesPrice,
  uploadedBy,
  numberOfLikes,
  waitingForApproval,
  showLikes,
  showLikeButton,
  showShopButton = true,
  removeLike,
}: ImageBoxProps) => {
  const [showModal, setShowModal] = useState(false);
  const [ring, setRing] = useState<IRing | undefined>(undefined);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const openModal = async () => {
    if (!ring) {
      try {
        const documentData = await getRingWithId(id);
        if (documentData) {
          // Cast documentData to a Ring type
          const ringFromServer = documentData as IRing;
          setRing(ringFromServer);
        }
      } catch (error) {
        console.error(error);
      }
    }

    setShowModal(true);
    window.history.pushState(null, "", `/ring/${id}`);
  };

  const closeModal = () => {
    setShowModal(false);
    window.history.back();
  };

  const navigateToRing = () => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      navigate(`/ring/${id}`);
    } else {
      openModal();
    }
  };

  return (
    <>
      {showModal && (
        <OverlayModal>
          <RingDetailedView close={closeModal} ring={ring} isModal={true} />
        </OverlayModal>
      )}
      <div key={id} className="box" style={{ backgroundImage: "url(" + url + ")" }}>
        {showLikes && (
          <div className="box__likes-box">
            {waitingForApproval ? (
              <WaitIcon className="box__wait-icon" />
            ) : (
              <>
                <LikeIcon className="box__like-icon" />
                <span>{numberOfLikes}</span>
              </>
            )}
          </div>
        )}
        <ImageButton onClick={() => navigateToRing()} className="box__more-button">
          {t("imageBox.More info")}
        </ImageButton>
        {showLikeButton && (
          <button
            className="box__unlike-button"
            onClick={(event) => removeLike && removeLike(event, id)}
          >
            <SavedIcon className="saved-icon" />
          </button>
        )}
        {showShopButton && salesUrl && (
          <button
            className="box__shop-link"
            onClick={() => {
              window.open(
                getUrlForAffiliate(
                  salesUrl,
                  typeof uploadedBy === "object" ? uploadedBy.id : uploadedBy,
                ),
                "_blank",
              );
              logEvent(analytics, "generate_lead", {
                value: salesPrice,
                currency: currency,
              });
            }}
          >
            <TagIcon className="tag-icon pressable" />
          </button>
        )}
      </div>
    </>
  );
};

export default ImageBox;
