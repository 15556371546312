import { useEffect, useState } from "react";
import "./styles/global.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

// pages
import InspoPage from "./pages/landing/landing.jsx";
import AdminPage from "./pages/admin/admin.jsx";
import Login from "./pages/login/login.tsx";
import TermsOfService from "./pages/terms-of-service/terms-of-service";
import SignUpPage from "./pages/sign-up/sign-up";
import UploadImage from "./pages/upload-image/upload-image";
import LikedRings from "./pages/liked-rings/liked-rings";
import Spinner from "./components/spinner/spinner";
import User from "./pages/user/user";
import RingPage from "./pages/ring/ring";
import ForgotPage from "./pages/forgot-password/forgot-password";

// firebase
import { createUserProfileDocument } from "./utils/firebase.auth.utils";
import { auth } from "./utils/firebase.utils";
import { onAuthStateChanged } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";

// Selectors and actions
import { selectCurrentUser } from "./redux/user/user.selectors";
import { setCurrentUser } from "./redux/user/user.actions";
import Collection from "./pages/collection/collection-viewer";

const App = ({ currentUser, setCurrentUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const unsubscribeFromAuth = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        return onSnapshot(userRef, (snapShot) => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data(),
          });
          setIsLoading(false);
        });
      }
      setCurrentUser(userAuth);
      setIsLoading(false);
    });

    return () => unsubscribeFromAuth();
  }, [setCurrentUser]);

  return (
    <div className="app">
      {isLoading ? (
        <Spinner theme="dark" />
      ) : (
        <Routes>
          <Route
            exact
            path="/login"
            element={currentUser ? <Navigate to="/" replace /> : <Login />}
          />
          <Route exact path="/terms" element={<TermsOfService />} />
          <Route
            exact
            path="/sign-up"
            element={currentUser ? <Navigate to="/" replace /> : <SignUpPage />}
          />
          <Route
            exact
            path="/admin"
            element={
              currentUser?.access !== "admin" ? (
                <Navigate to="/" replace />
              ) : (
                <AdminPage />
              )
            }
          ></Route>
          <Route exact path="/upload-ring" element={<UploadImage />} />
          <Route exact path="/forgot-password" element={<ForgotPage />} />
          <Route
            exact
            path="/liked-rings"
            element={currentUser ? <LikedRings /> : <Navigate to="/" replace />}
          ></Route>
          <Route exact path="/profile/:userid" element={<User />} />
          <Route path="/" element={<InspoPage />} />
          <Route exact path="/ring/:ringid" element={<RingPage />} />
          <Route exact path="/collection/:collectionId" element={<Collection />} />
        </Routes>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
