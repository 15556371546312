import { FormEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./sign-in-email.scss";

// components
import FormInput from "../form-input/form-input";
import LoginButton from "../login-button/login-button";
import Spinner from "../spinner/spinner";

// firebase
import { auth, updatePropForUserWithId } from "../../utils/firebase.utils";
import { signInWithEmailAndPassword } from "firebase/auth";

const SignInEmail = () => {
  const { t } = useTranslation();
  const [Password, setPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);

   useEffect(() => {
    const fetchIpAndCountry = async () => {
      try {
        const response = await fetch("https://getcountryfromip-eult3duyqq-uc.a.run.app");
        const data = await response.json();
        setIpAddress(data.ip);
        setCountry(data.country);
      } catch (error) {
        console.error("Error fetching IP and country:", error);
      }
    };

    fetchIpAndCountry();
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const { user } = await signInWithEmailAndPassword(auth, Email, Password);
      
      if (user) {
        await updatePropForUserWithId("ipAddress", ipAddress, user.uid);
        await updatePropForUserWithId("country", country, user.uid);
      }
    } catch (error: any) {
      setPassword("");
      const { code } = error;
      switch (code) {
        case "auth/wrong-password":
          alert(t("signInEmail.wrong password or user does not exist"));
          break;
        default:
          console.error(code);
          alert(error.message);
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };
  return isLoading ? (
    <Spinner theme="dark" />
  ) : (
    <div className="sign-in-email">
      <h2>{t("signInEmail.login")}</h2>
      <span>{t("signInEmail.please provide your login details below")}</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          name="email"
          type="email"
          label={t("signInEmail.email")}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setEmail(target.value)}
          value={Email}
          autoComplete="email"
          required
        />
        <FormInput
          name="password"
          type="password"
          label={t("signInEmail.password")}
          value={Password}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setPassword(target.value)}
          required
        />
        <LoginButton type="submit">{t("signInEmail.sign in with email")}</LoginButton>
      </form>
      <Link to="/forgot-password">{t("signInEmail.forgot your password")}</Link>
    </div>
  );
};

export default SignInEmail;
