import React, { ReactNode } from "react";
import "./filter-button.scss";

export interface IFilterButton {
  isActive: boolean;
  isReverse?: boolean;
  children: ReactNode;
  onClick?: () => void | {};
}

const FilterButton = ({ isActive, isReverse, children, onClick, ...otherprops }: IFilterButton) => {
  return (
    <button
      className={`${isActive && "active"} ${isReverse && "reverse"} filter-button`}
      style={onClick ? { cursor: "pointer" } : { cursor: "default" }}
      onClick={onClick}
      {...otherprops}
    >
      {children}
    </button>
  );
};

export default FilterButton;
