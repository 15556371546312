import React, { useState, useEffect, FC } from "react";
import "./liked-rings.scss";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import { CSSTransition } from "react-transition-group";

// Utils
import { fetchRingsByIds } from "../../utils/firebase.utils";

// Selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectModalHidden } from "../../redux/modal/modal.selectors";
import { toggleModalHidden } from "../../redux/modal/modal.actions";
import { selectSidebarHidden } from "../../redux/sidebar/sidebar.selectors";
import { toggleSidebarHidden } from "../../redux/sidebar/sidebar.actions";
import { selectLikedRingUrls, selectLikedRings } from "../../redux/rings/rings.selectors";
import { setLikedRingUrls, setLikedRings } from "../../redux/rings/rings.actions";

// Components
import BasePage from "../../components/base-page/base-page";
import OverlayModal from "../../components/overlay-modal/overlay-modal";
import SaveImages from "../../components/modals/save-images";
import ThankYou from "../../components/modals/thank-you";
import Sidebar from "../../components/sidebar/sidebar";
import LikedRingsContainer from "../../components/liked-rings-container/liked-rings-container";

type LikedRingsProps = ConnectedProps<typeof connector>;

const LikedRings: FC<LikedRingsProps> = ({
  currentUser,
  modalHidden,
  toggleModal,
  sidebarHidden,
  toggleSidebar,
  setLikedRingsState,
  likedRingsState,
  setLikedRingUrlsState,
  likedRingUrlsState,
}) => {
  const [likedRings, setLikedRings] = useState<any[]>([]);
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!currentUser || !currentUser.likedRings) return;

    const uniqueLikedIds: Set<string> = new Set(
      currentUser.likedRings.map((ringId: any) => (typeof ringId === "object" ? ringId.id : ringId)),
    );

    const uniqueLikedIdsArray: string[] = Array.from(uniqueLikedIds);

    if (likedRingsState && likedRingsState.length === uniqueLikedIdsArray.length)
      return setLikedRings(likedRingsState);

    setIsLoading(true);
    fetchRingsByIds(uniqueLikedIdsArray).then((result) => {
      setLikedRings(result);
      setLikedRingsState(result);
      setIsLoading(false);
    });
  }, [currentUser, setLikedRingsState]);

  useEffect(() => {
    setLikedRingUrlsState(
      likedRings.map((ring) => (Array.isArray(ring.url) ? ring.url[0] : ring.url)),
    );
  }, [likedRings, setLikedRingUrlsState]);

  const toggleModalAndOpenThankYouModal = () => {
    toggleModal();
    setIsSent(false);
  };

  const handleOnSend = () => {
    setIsSent(true);
  };

  return (
    <BasePage showShare={true} showStatistics={true}>
      {!modalHidden && (
        <OverlayModal close={toggleModalAndOpenThankYouModal}>
          {!isSent ? (
            <SaveImages likedRingUrls={likedRingUrlsState} imagesSaved={handleOnSend} />
          ) : (
            <ThankYou closeModal={toggleModalAndOpenThankYouModal} />
          )}
        </OverlayModal>
      )}
      <CSSTransition in={!sidebarHidden} timeout={200} classNames="fade" unmountOnExit>
        <OverlayModal close={toggleSidebar}>
          <Sidebar title="Your statistics" likedRings={likedRings} isLoading={isLoading}/>
        </OverlayModal>
      </CSSTransition>
      <div className="liked-rings">
        <LikedRingsContainer
          likedRingsParent={likedRings}
          setLikedRingsParent={setLikedRings}
          isLoading={isLoading}
        />
      </div>
    </BasePage>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  toggleModal: () => dispatch(toggleModalHidden()),
  toggleSidebar: () => dispatch(toggleSidebarHidden()),
  setLikedRingsState: (rings: any[]) => dispatch(setLikedRings(rings)),
  setLikedRingUrlsState: (urls: string[]) => dispatch(setLikedRingUrls(urls)),
});

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  modalHidden: selectModalHidden,
  sidebarHidden: selectSidebarHidden,
  likedRingsState: selectLikedRings,
  likedRingUrlsState: selectLikedRingUrls,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LikedRings);
