import { combineReducers } from "redux";

// reducers
import userReducer from "./user/user-reducer";
import SidebarReducer from "./sidebar/sidebar-reducer";
import ModalReducer from "./modal/modal-reducer";
import ringsReducer from "./rings/rings-reducer";

export default combineReducers({
  user: userReducer,
  sidebar: SidebarReducer,
  modal: ModalReducer,
  rings: ringsReducer,
});
