import { useState } from "react";
import FormInput from "../form-input/form-input";
import "./create-collection-modal.scss";
import { useTranslation } from "react-i18next";
import { createListWithName } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { IList } from "../../utils/interfaces";

interface CreateCollectionModalProps {
  close: () => void;
  addCollection: (collection: IList) => void;
}

const CreateCollectionModal = ({ close, addCollection }: CreateCollectionModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const currentUser = useSelector(selectCurrentUser);

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      close();
    }
  };

  const createCollection = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name.trim()) return;
    if (!currentUser || !currentUser.id) {
      console.error("User is not authenticated.");
      return;
    }

    try {
      const newListId = await createListWithName(name.trim(), currentUser.id);
      console.log(`List created successfully with ID: ${newListId}`);
      addCollection({
        name: name.trim(),
        createdBy: currentUser.id,
        createdOn: new Date(),
        id: newListId,
      });
      close();
    } catch (error) {
      console.error("Failed to create the list:", error);
      alert(error);
    }
  };

  return (
    <div className="save-to-collection-modal__overlay" onClick={handleBackgroundClick}>
      <div className="save-to-collection-modal">
        <div className="save-to-collection-modal__content">
          <div className="save-to-collection-modal__header">
            {t("createCollection.Create collection")}
          </div>
          <form onSubmit={createCollection} className="save-to-collection-modal__content__list">
            <FormInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              label={t("createCollection.Collection name")}
              autoFocus
              required
              black
            />
            <button className="save-to-collection-modal__submit-button" type="submit">
              {t("general.Submit")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCollectionModal;
