import React from "react";
import { useTranslation } from "react-i18next";
import "./terms.scss";

const Terms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="terms">
      <div className="paper">
        <h2>{t('terms.Terms of Service')}</h2>
        <p>{t('terms.Welcome to Rinder!')}</p>
        <p>
          {t('terms.These Terms of Service (or "Terms") govern your use of Rinder. When you create a Rinder account or use Rinder, you agree to these terms.')}
        </p>
        <p>
          {t('terms.The Rinder Service is provided to you by TIDN AB. These Terms of Service therefore constitute an agreement between you and TIDN AB. Registered at Lundagatan 36C, STOCKHOLM, SWEDEN. With registration number 559265-3249.')}
        </p>
        <h3>{t('terms.The Rinder Service')}</h3>
        <p>
          {t('terms.The Rinder service includes software, applications, services, products and applications. The main service we offer is summarized below:')}
        </p>
        <ul>
          <li>
            {t('terms.Our primary focus is to provide you with an endless stream of stunning, AI-curated jewelry selections for your enjoyment. To achieve this, we have developed systems that aim to understand your preferences and those of others. This allows us to offer you better and more accurate inspiration for your next project. As part of this process, we also highlight jewelry, offers, and vendors that align with your interests.')}
          </li>
          <li>
            {t('terms.We strive to offer uninterrupted service with a strong emphasis on usability. To ensure a consistently high standard and ease of use, we employ analytics and tracking mechanisms, ensuring that Rinder functions seamlessly, regardless of your location.')}
          </li>
        </ul>
        <h3>{t('terms.How Our Service Is Funded')}</h3>
        <p>
          {t('terms.Unlike traditional payment models, at Rinder, you do not pay to use our platform. Instead, vendors pay us to showcase their products or services to you. Rest assured, we do not sell your personal data. Our goal is to provide you with the best possible platform to explore, design, and purchase jewelry online.')}
        </p>
        <p>
          {t('terms.To assist vendors in enhancing their offerings, we may provide them with performance analytics on how well their jewelry performs. This information gives them valuable insights and opportunities to improve their products and services.')}
        </p>
        <h3>{t('terms.The Data Policy')}</h3>
        <p>{t('terms.Providing our Service requires handling the information you provide.')}</p>
        <h3>{t('terms.Your Commitments')}</h3>
        <p>
          {t('terms.We want our Service to be as easy to use as possible, but we also want it to be safe, secure, and in accordance with the law. So, we need you to commit to a few restrictions in order to be part of the Rinder community.')}
        </p>
        <ul>
          <li>{t('terms.You must be at least 13 years old.')}</li>
          <li>
            {t('terms.You must not be prohibited from receiving any aspect of our Service under applicable laws.')}
          </li>
        </ul>
        <p>{t("terms.How You Can't Use Rinder. To keep Rinder running we need to avoid litigation.")}</p>
        <ul>
          <li>
            {t("terms.You can't impersonate others.")}
            <br />
            <br />
          </li>
          <li>
            {t("terms.Share only photos that you’ve taken or have the right to share. This means that you own the content you upload to Rinder. Remember to post authentic content, and don’t post anything you’ve copied or collected from the Internet that you don’t have the right to post. Please help by reporting content that you consider to be violating our terms of service.")}
          </li>
          <li>
            {t("terms.Don’t spend time trying to obstruct the way our service is supposed to operate. We love rings, we don’t love spending time administering blatant obstruction.")}
          </li>
          <li>
            {t("terms.Do not create accounts and then automate some process. The accounts are meant for personal use.")}
          </li>
        </ul>
        <p>{t('terms.Permissions you give us in order for us to provide the service:')}</p>
        <ul>
          <li>
            {t("terms.All content uploaded to the platform is not owned by us but we are granted a license to use it.")}
            <br />
            {t("terms.While the rights to the content you upload doesn’t change we need certain legal permissions from you (known as a “license”) to provide the Service. When you upload images that are covered by intellectual property rights (like photos or videos) on or in connection with our Service, you hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). This license will end when your content is deleted from our systems.")}
          </li>
          <li>
            {t("terms.Permission to use your username, profile picture, and information about your saved rings on the platform or channels operated by the platform.")}
            <br />
            <br />
          </li>
        </ul>
        <h3>{t('terms.Additional Rights We Retain')}</h3>
        <ul>
          <li>
            {t('terms.You must obtain written permission from us or under an open source license to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.')}
          </li>
        </ul>
        <h3>{t('terms.Content Removal and Disabling or Terminating Your Account')}</h3>
        <ul>
          <li>
            {t('terms.We can remove any content or information you share on the Service if we believe that it violates these Terms of Service or we are required to do so by law. We can refuse to provide or stop providing all or part of the Service to you immediately to protect our community or services, or if you create risk or legal exposure for us or violate these Terms of Service.')}
          </li>
          <li>
            {t('terms.If your account is deleted the below terms, “Our agreement and what happens if we disagree”, still apply.')}
          </li>
        </ul>
        <h3>{t('terms.Our Agreement and What Happens if We Disagree')}</h3>
        <p>{t('terms.Our Agreement.')}</p>
        <ul>
          <li>{t('terms.If any aspect of this agreement is unenforceable, the rest will remain in effect.')}</li>
          <li>
            {t('terms.Any amendment or waiver to our agreement must be in writing and signed by us. If we fail to enforce any aspect of this agreement, it will not be a waiver.')}
          </li>
          <li>{t('terms.We reserve all rights not expressly granted to you.')}</li>
        </ul>
        <p>{t('terms.Who Has Rights Under this Agreement.')}</p>
        <ul>
          <li>{t('terms.This agreement does not give rights to any third parties.')}</li>
          <li>
            {t('terms.You cannot transfer your rights or obligations under this agreement without our consent.')}
          </li>
          <li>
            {t('terms.Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes (as in a merger, acquisition, or sale of assets) or by law.')}
          </li>
        </ul>
        <p>{t('terms.Who Is Responsible if Something Happens.')}</p>
        <ul>
          <li>
            {t('terms.We will use our utmost skill and care to make sure that the platform provides a safe and secure environment without errors. However if an error should occur we do not take responsibility for losses indirectly related to our platform.')}
          </li>
        </ul>
        <p>{t('terms.How We Will Handle Disputes.')}</p>
        <p>{t('terms.Any claims will be handled under Swedish court of law.')}</p>
        <p>{t('terms.Unsolicited Material.')}</p>
        <p>
          {t('terms.We always appreciate feedback or other suggestions, but may use them without any restrictions or obligation to compensate you for them, and are under no obligation to keep them confidential.')}
        </p>
        <h3>{t('terms.Updating These Terms')}</h3>
        <p>
          {t('terms.We are a quickly growing platform and may at any time need to update these terms of service. We will try to keep you updated. However if we should fail please rely on this page and keep yourself updated here.')}
        </p>
        <p>{t('terms.Updated: 2023 June 13th')}</p>
      </div>
    </div>
  );
};

export default Terms;