export const affiliateIds = ["KCoPkmzGxIh0jIMf12aVtyRP9Xc2"];

export const getUrlForAffiliate = (url, userId) => {
  if (!affiliateIds.includes(userId)) {
    return url;
  }

  switch (userId) {
    case "KCoPkmzGxIh0jIMf12aVtyRP9Xc2":
      return url + "?linkId=Rinder_influencer_influencer&affluencerId=Rinder";

    default:
      return url;
  }
};
