import { doc, setDoc, getDoc, DocumentReference } from "firebase/firestore";
import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  User,
  UserCredential,
} from "firebase/auth";
import { firestore, auth, updatePropForUserWithId } from "./firebase.utils";

interface AdditionalData {
  ipAddress?: string | null;
  country?: string | null;
  [key: string]: any;
}

export const createUserProfileDocument = async (
  userAuth: User | null,
  additionalData?: AdditionalData,
): Promise<DocumentReference | void> => {
  if (!userAuth) return;

  const userRef = doc(firestore, `users/${userAuth.uid}`);

  const snapShot = await getDoc(userRef);

  if (!snapShot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = Date.now();

    try {
      await setDoc(userRef, {
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error: any) {
      console.error("error creating user", error.message);
    }
  }

  return userRef;
};

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const fetchIpAndCountry = async (): Promise<{
  ipAddress: string | null;
  country: string | null;
}> => {
  try {
    const response = await fetch("https://getcountryfromip-eult3duyqq-uc.a.run.app");
    const data = await response.json();
    return {
      ipAddress: data.ip,
      country: data.country,
    };
  } catch (error) {
    console.error("Error fetching IP and country:", error);
    return { ipAddress: null, country: null };
  }
};

export const signInWithGoogle = async (): Promise<UserCredential | void> => {
  try {
    const result = await signInWithPopup(auth, provider);

    const { ipAddress, country } = await fetchIpAndCountry();

    if (ipAddress && country) {
      const { uid } = result.user;

      await updatePropForUserWithId("ipAddress", ipAddress, uid);
      await updatePropForUserWithId("country", country, uid);
    } else {
      console.error("Failed to fetch IP address and country.");
    }

    return result;
  } catch (error: any) {
    console.error("error signing in with Google", error.message);
  }
};

export const signUpWithEmailAndPassword = async (
  email: string,
  password: string,
): Promise<User | void> => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error: any) {
    console.error("error signing up with email and password", error.message);
  }
};
