import React from "react";
import "./custom-list-item.scss";

import { ReactComponent as Asscher } from "../../assets/shapes/asscher.svg";
import { ReactComponent as Baguette } from "../../assets/shapes/baguette.svg";
import { ReactComponent as Cushion } from "../../assets/shapes/cushion.svg";
import { ReactComponent as Emerald } from "../../assets/shapes/emerald.svg";
import { ReactComponent as Heart } from "../../assets/shapes/heart.svg";
import { ReactComponent as Marquise } from "../../assets/shapes/marquise.svg";
import { ReactComponent as Mine } from "../../assets/shapes/mine.svg";
import { ReactComponent as Octagon } from "../../assets/shapes/octagon.svg";
import { ReactComponent as Oval } from "../../assets/shapes/oval.svg";
import { ReactComponent as Pear } from "../../assets/shapes/pear.svg";
import { ReactComponent as Princess } from "../../assets/shapes/princess.svg";
import { ReactComponent as Radiant } from "../../assets/shapes/radiant.svg";
import { ReactComponent as Rose } from "../../assets/shapes/rose.svg";
import { ReactComponent as Round } from "../../assets/shapes/round.svg";
import { ReactComponent as Trillion } from "../../assets/shapes/trillion.svg";
import { ReactComponent as HeartIcon } from "../../assets/favorite.svg";

const CustomListItem = ({ count, shape, percentage }) => {
  return (
    <div className="custom-listitem">
      <div className="custom-listitem__container">
        <ShapeImage shape={shape} />
        <div className="custom-listitem__nameandcount">
          <span className="custom-listitem__name">{shape}</span>
          <span className="custom-listitem__count">{count}</span>
        </div>
        <span className="custom-listitem__percentage">
          <HeartIcon className="custom-listitem__heart" />
          <span>{(100 * percentage).toFixed(0)}%</span>
        </span>
      </div>
    </div>
  );
};

export default CustomListItem;

const ShapeImage = ({ shape }) => {
  return (
    <>
      {shape === "asscher" && <Asscher className="custom-listitem__icon" />}
      {shape === "baguette" && <Baguette className="custom-listitem__icon" />}
      {shape === "cushion" && <Cushion className="custom-listitem__icon" />}
      {shape === "emerald" && <Emerald className="custom-listitem__icon" />}
      {shape === "heart" && <Heart className="custom-listitem__icon" />}
      {shape === "marquise" && <Marquise className="custom-listitem__icon" />}
      {shape === "omc" && <Mine className="custom-listitem__icon" />}
      {shape === "octagon" && <Octagon className="custom-listitem__icon" />}
      {shape === "oval" && <Oval className="custom-listitem__icon" />}
      {shape === "pear" && <Pear className="custom-listitem__icon" />}
      {shape === "princess" && <Princess className="custom-listitem__icon" />}
      {shape === "radiant" && <Radiant className="custom-listitem__icon" />}
      {shape === "rose" && <Rose className="custom-listitem__icon" />}
      {shape === "round" && <Round className="custom-listitem__icon" />}
      {shape === "trillion" && <Trillion className="custom-listitem__icon" />}
    </>
  );
};
