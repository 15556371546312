// components
import FilterButton from "../filter-button/filter-button";

// utils
import { getKeyByValue, getKeysByValue } from "../../utils/object.utils";
import { capitalizeFirstLetter } from "../../utils/string.utils";
import {
  caratData,
  getLabelInDataByValue,
  metalTagsData,
  stoneCutTagsData,
  stoneTypeTagsData,
  colorTagsData,
  styleTagsData,
  settingTagsData,
} from "../../utils/constants.utils";
import { useTranslation } from "react-i18next";

// interface
interface IFilter {
  setFilters: (filters: any) => void;
  filters: IFilters
}

interface IFilters {
  
    mainStoneSize: {
      [key: string]: boolean;
    };
    stoneCut: {
      [key: string]: boolean;
    };
}

const Filter = ({ setFilters, filters }: IFilter) => {
  const {t} = useTranslation();

  const updateUrlParams = (filters: IFilters) => {
  const params = new URLSearchParams(window.location.search);

  // Update stoneSize in the URL
  const selectedSizes = getKeysByValue(filters.mainStoneSize, true);
  const allSizesSelected = Object.keys(filters.mainStoneSize).length === selectedSizes.length;

  if (selectedSizes.length > 0 && !allSizesSelected) {
    params.set('stoneSize', selectedSizes.join(','));
  } else {
    params.delete('stoneSize');
  }

  // Update tags in the URL
  const selectedTags = getKeysByValue(filters.stoneCut, true);
  const allTagsSelected = Object.keys(filters.stoneCut).length === selectedTags.length;

  if (selectedTags.length > 0 && !allTagsSelected) {
    params.set('tags', selectedTags.join(','));
  } else {
    params.delete('tags');
  }

  // Update the URL without reloading the page
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, '', newUrl);
}


  const toggleStoneSize = (size = 5) => {
    const { mainStoneSize } = filters;
    if (!getKeyByValue(mainStoneSize, false)) {
      Object.keys(mainStoneSize).forEach((v) => (mainStoneSize[v] = false));
    }

    const trueKeys = getKeysByValue(mainStoneSize, true);
    if (trueKeys.length === 1 && trueKeys[0] === size.toString()) {
      Object.keys(mainStoneSize).forEach((v) => (mainStoneSize[v] = true));
    }

    setFilters((prevFilters:any) => {
    const updatedFilters = {
      ...prevFilters,
      mainStoneSize: {
        ...prevFilters.mainStoneSize,
        [size]: !prevFilters.mainStoneSize[size],
      },
    };
    
    // Update the URL with the new filter state
    updateUrlParams(updatedFilters);

    return updatedFilters;
  });
  };

  const toggleStoneShape = (shape: string) => {
    const { stoneCut } = filters;
    if (!getKeyByValue(stoneCut, false)) {
      Object.keys(stoneCut).forEach((v: any) => (stoneCut[v] = false));
    }

    const trueKeys = getKeysByValue(stoneCut, true);
    if (trueKeys.length === 1 && trueKeys[0] === shape) {
      Object.keys(stoneCut).forEach((shape) => {
        if (shape !== "cad") {
          stoneCut[shape] = true;
        } else {
          stoneCut[shape] = false;
        }
      });
    }

    setFilters((prevFilters:any) => {
    const updatedFilters = {
      ...prevFilters,
      stoneCut: {
        ...prevFilters.stoneCut,
        [shape]: !prevFilters.stoneCut[shape],
      },
    };
    
    // Update the URL with the new filter state
    updateUrlParams(updatedFilters);

    return updatedFilters;
  });
  };
  return (
    <>
      <label>{t('sidebar.Ring type')}</label>
      <div>
        <FilterButton isActive={true}>{t('sidebar.Women')}</FilterButton>
      </div>

      <label>{t('sidebar.Stone size')}</label>
      <div>
        {Object.keys(filters.mainStoneSize).map((caratSize, index) => (
          <FilterButton
            key={index}
            isActive={filters.mainStoneSize[caratSize] === true}
            onClick={() => toggleStoneSize(parseFloat(caratSize))}
          >{`${getLabelInDataByValue(caratData, caratSize)} carat`}</FilterButton>
        ))}
      </div>
      <label>{t('sidebar.Stone cut')}</label>
      <div>
        {stoneCutTagsData.map(({label, value}, index) => (
          <FilterButton
            key={index}
            isActive={filters.stoneCut[value] === true}
            onClick={() => toggleStoneShape(value)}
          >
            {capitalizeFirstLetter(getLabelInDataByValue([...stoneTypeTagsData, ...stoneCutTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData], value))}
          </FilterButton>
        ))}
      </div>
      <label>{t('sidebar.Stone type')}</label>
      <div>
        {stoneTypeTagsData.map(({label, value}, index) => (
          <FilterButton
            key={index}
            isActive={filters.stoneCut[value] === true}
            onClick={() => toggleStoneShape(value)}
          >
            {capitalizeFirstLetter(getLabelInDataByValue([...stoneTypeTagsData, ...stoneCutTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData], value))}
          </FilterButton>
        ))}
      </div>
      <label>{t('sidebar.Stone color')}</label>
      <div>
        {colorTagsData.map(({label, value}, index) => (
          <FilterButton
            key={index}
            isActive={filters.stoneCut[value] === true}
            onClick={() => toggleStoneShape(value)}
          >
            {capitalizeFirstLetter(getLabelInDataByValue([...stoneTypeTagsData, ...stoneCutTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData], value))}
          </FilterButton>
        ))}
      </div>
      <label>{t('sidebar.Setting')}</label>
      <div>
        {settingTagsData.map(({label, value}, index) => (
          <FilterButton
            key={index}
            isActive={filters.stoneCut[value] === true}
            onClick={() => toggleStoneShape(value)}
          >
            {capitalizeFirstLetter(getLabelInDataByValue([...stoneTypeTagsData, ...stoneCutTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData], value))}
          </FilterButton>
        ))}
      </div>
      <label>{t('sidebar.Style')}</label>
      <div>
        {styleTagsData.map(({label, value}, index) => (
          <FilterButton
            key={index}
            isActive={filters.stoneCut[value] === true}
            onClick={() => toggleStoneShape(value)}
          >
            {capitalizeFirstLetter(getLabelInDataByValue([...stoneTypeTagsData, ...stoneCutTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData], value))}
          </FilterButton>
        ))}
      </div>
      <label>{t('sidebar.Metal')}</label>
      <div>
        {metalTagsData.map(({label, value}, index) => (
          <FilterButton
            key={index}
            isActive={filters.stoneCut[value] === true}
            onClick={() => toggleStoneShape(value)}
          >
            {capitalizeFirstLetter(getLabelInDataByValue([...stoneTypeTagsData, ...stoneCutTagsData, ...colorTagsData, ...styleTagsData, ...metalTagsData, ...settingTagsData], value))}
          </FilterButton>
        ))}
      </div>
    </>
  );
};

export default Filter;
