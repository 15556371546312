import React, { useState } from "react";
import "./header.scss";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { Dispatch } from "redux";

import { createStructuredSelector } from "reselect";

// svg
import { ReactComponent as LogoIcon } from "../../assets/rinder.svg";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import { ReactComponent as ShareIcon } from "../../assets/share.svg";
import { ReactComponent as StatisticsIcon } from "../../assets/statistics.svg";

// selectors and actions
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { toggleSidebarHidden } from "../../redux/sidebar/sidebar.actions";
import { toggleModalHidden } from "../../redux/modal/modal.actions";
import { selectLikedRingUrls } from "../../redux/rings/rings.selectors";
import { useTranslation } from "react-i18next";
import Toast from "../toast/toast";

interface IHeader {
  toggleSidebarHidden: () => void;
  toggleModalHidden: () => void;
  currentUser: any;
  showFilter?: boolean;
  showShare?: boolean;
  showStatistics?: boolean;
  likedRingUrls?: any;
  showLikedRingUrls?: boolean;
}

const Header = ({
  toggleSidebarHidden,
  toggleModalHidden,
  currentUser,
  showFilter = false,
  showShare = false,
  showStatistics = false,
  likedRingUrls,
  showLikedRingUrls = false,
}: IHeader) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 });

  const isDesktop = () => {
    const isDesktop = window.innerWidth >= 768;
    return isDesktop;
  };

  const handleCopyLinkToRings = (event: React.MouseEvent<Element>) => {
    copyLinkToRings();
    const rect = event.currentTarget.getBoundingClientRect();

    setToastPosition({ top: rect.top, left: rect.left });
    setShowToast(true);
  };

  const copyLinkToRings = () => {
    if (currentUser) {
      const link = `https://rinderr.com/profile/${currentUser.id}#liked-rings`;
      navigator.clipboard
        .writeText(link)
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    }
  };

  return (
    <div className="header">
      <Toast
        message="Link to liked rings copied successfully"
        show={showToast}
        onClose={() => setShowToast(false)}
        top={toastPosition.top}
        left={toastPosition.left}
      />

      {showShare && <div className="header__icon-button-nobg">
          <ShareIcon className="icon share-icon" onClick={handleCopyLinkToRings} />
          <label>
          {t('header.Share')}
          </label>
       </div>}
      {showLikedRingUrls &&
      likedRingUrls &&
      isDesktop() &&
      likedRingUrls.length > 0 &&
      !currentUser ? (
        <button className="primary share-icon" onClick={toggleModalHidden}>
          {t("header.Save count liked rings", { count: likedRingUrls.length })}
        </button>
      ) : (
        <div></div>
      )}
      {likedRingUrls && !isDesktop() && likedRingUrls.length > 0 && !currentUser ? (
        <div className="header__share-icon-container">
          <ShareIcon className="icon share-icon" onClick={toggleModalHidden} />
          <span className="header__notification-small">{likedRingUrls.length}</span>
        </div>
      ) : (
        <div></div>
      )}
      <div className="header__logo">
        <LogoIcon className="logo" onClick={() => navigate("/")} />
      </div>
      {currentUser && showFilter && (
        <button className="header__icon-button" onClick={toggleSidebarHidden}>
          <FilterIcon className="icon filter-icon" />
          <label>
          {t('header.Filter')}
          </label>
        </button>
      )}
      {showStatistics && (
        <div className="header__icon-button-nobg">
          <StatisticsIcon onClick={toggleSidebarHidden} className="icon statistics-icon" />
          <label>
          {t('header.Statistics')}
          </label>
       </div>
      )}
      {!currentUser && (
        <button className="primary inverted" onClick={() => navigate("/login")}>
          {t("header.Sign in")}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = () =>
  createStructuredSelector({
    currentUser: selectCurrentUser,
    likedRingUrls: selectLikedRingUrls,
  });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleSidebarHidden: () => dispatch(toggleSidebarHidden()),
  toggleModalHidden: () => dispatch(toggleModalHidden()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
