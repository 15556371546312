import * as React from "react";
import "./buttons.scss";

interface IImageButtonProps {
  children: React.ReactNode;
  onClick: React.ReactEventHandler;
  className?: string;
}

const ImageButton = ({ children, onClick, className }: IImageButtonProps) => {
  return (
    <button onClick={onClick} className={"image__button pressable " + className}>
      {children}
    </button>
  );
};

export default ImageButton;
